import React from "react";

import { Animation } from "@/gql/generated/graphql";

import { AnimationListItem, ListItemAnimation } from "./animation-list-item";

import "./animation-list.scss";

type AnimationListProps = {
  animations: ListItemAnimation[];
  showPublic?: boolean;
  selectMode?: boolean;
  onAnimationSelected?: () => void;
  selectedItems?: number[];
};

const AnimationList = ({
  animations,
  showPublic = false,
  selectMode = false,
  onAnimationSelected,
  selectedItems = [],
}: AnimationListProps) => {
  const renderList = () => {
    return animations.map((animation, index) => (
      <AnimationListItem
        animation={animation}
        showPublic={showPublic}
        key={`${index}-${animation.id}`}
        selectMode={selectMode}
        onAnimationSelected={onAnimationSelected}
        selected={selectedItems.includes(animation.id)}
      />
    ));
  };

  return (
    <div className="animation-list__container">
      <ol className="animation-list">{renderList()}</ol>
    </div>
  );
};

export default AnimationList;
