"use client";
import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { setUserAvatar } from "@/actions/set-user-avatar";
import { AnimationFileList } from "@/components";
import Button from "@/components/button";
import { ButtonBar, Module } from "@/components/layouts";
import { ButtonGroup } from "@/components/layouts/button-group";
import { AnimationType } from "@/gql/generated/graphql";
import { MY_STUDIO } from "@/gql/queries/my-studio";
import { useModal } from "@/hooks/use-modal";

import ConfirmCreateAvatarModal from "./confirm-create-avatar";
import ModalTitle from "./modal-title";

const ANIMATIONS_PER_PAGE = 6;

export default function ChooseAvatarModal() {
  const { closeModal, openModal } = useModal();
  const [currentPage, setCurrentPage] = useState(0);

  const onAnimationSelected = async (animationUrl: string) => {
    await setUserAvatar(animationUrl);
    closeModal();
  };

  const onPrevPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const onNextPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const onCreatePressed = () => {
    openModal(() => <ConfirmCreateAvatarModal />);
  };

  const {
    data = {
      privateAnimations: new Array(ANIMATIONS_PER_PAGE).fill({}),
      privateAnimationsCount: 0,
      animationFolders: [],
    },
  } = useQuery(MY_STUDIO, {
    variables: {
      offset: currentPage * ANIMATIONS_PER_PAGE,
      limit: ANIMATIONS_PER_PAGE,
      types: [AnimationType.Avatar],
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <div>
      <ModalTitle>Choose Profile Picture</ModalTitle>
      <ButtonBar>
        <ButtonGroup>
          <Button
            onClick={onCreatePressed}
            icon="fas fa-plus-circle"
            type="positive"
          >
            Create new profile pic
          </Button>
        </ButtonGroup>
        <ButtonGroup align="right">
          <Button
            onClick={onNextPage}
            disabled={currentPage === 0}
            icon="fas fa-arrow-circle-left"
          >
            Newer
          </Button>
          <Button
            onClick={onPrevPage}
            disabled={data.privateAnimations.length < ANIMATIONS_PER_PAGE}
            icon="fas fa-arrow-circle-right"
          >
            Older
          </Button>
        </ButtonGroup>
      </ButtonBar>
      <Module>
        {
          <AnimationFileList
            animations={data.privateAnimations}
            onAnimationSelected={onAnimationSelected}
          />
        }
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button onClick={closeModal} type="negative">
            <i className="fa fa-ban" /> Cancel
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
