"use client";
import React, { FC, MouseEvent, useRef } from "react";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";

import Button from "@/components/button";
import { Textarea } from "@/components/forms";
import IfUserIs from "@/components/if-user-is";
import { ButtonBar, Module } from "@/components/layouts";
import { ButtonGroup } from "@/components/layouts/button-group";
import { CoreAnimationFieldsFragment, Thread } from "@/gql/generated/graphql";
import { MUTATE_CREATE_POST } from "@/gql/queries/mutate-create-post";
import { useModal } from "@/hooks/use-modal";

import PostBanned from "../modal/components/post-banned";
import PostRateLimited from "../modal/components/post-rate-limited";
import UserBanned from "../modal/components/user-banned";
import UserUnverified from "../modal/components/user-unverified";

type PostEditorProps = {
  animation?: Pick<CoreAnimationFieldsFragment, "__typename" | "id">;
  threadId?: number;
  type: Thread["type"];
};

const PostEditor: FC<PostEditorProps> = ({ animation, threadId, type }) => {
  const { openModal } = useModal();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [mutateCreatePost, { loading }] = useMutation(MUTATE_CREATE_POST, {
    onCompleted: () => {
      if (!textareaRef.current) return;
      textareaRef.current.value = "";
    },
    onError: (e) => {
      const { code, expiry } = e.graphQLErrors[0].extensions;

      switch (code) {
        case "POST_RATE_LIMITED":
          openModal(() => <PostRateLimited />);
          break;

        case "POST_BANNED":
          openModal(() => (
            <PostBanned bannedUntil={dayjs().to(dayjs(expiry as string))} />
          ));
          break;

        case "USER_BANNED":
          openModal(() => <UserBanned />);
          break;

        case "USER_UNVERIFIED":
          openModal(() => <UserUnverified />);
          break;
      }
    },
    update(cache, { data }) {
      if (animation && data?.createPost) {
        cache.modify({
          id: cache.identify(animation),
          fields: {
            thread() {
              return data.createPost;
            },
          },
        });
      }
    },
  });

  const onPostButtonClicked = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (textareaRef?.current?.value.trim().length === 0) return;

    mutateCreatePost({
      variables: {
        text: textareaRef?.current?.value.trim() ?? "",
        threadId: threadId,
        animationId: animation?.id,
        type,
        lock: e.currentTarget.dataset["lock"] === "true",
      },
    });
  };

  const getPostButton = () => {
    return (
      <ButtonGroup>
        {!loading && (
          <Button
            icon="far fa-paper-plane"
            onClick={onPostButtonClicked}
            type="positive"
          >
            Post comment
          </Button>
        )}
        {loading && (
          <Button
            icon="fas fa-spin fa-spinner"
            disabled
            onClick={onPostButtonClicked}
          >
            Posting...
          </Button>
        )}
        <IfUserIs admin>
          <Button
            icon="fas fa-lock"
            data-lock="true"
            type="negative"
            onClick={onPostButtonClicked}
          >
            Post and lock
          </Button>
        </IfUserIs>
      </ButtonGroup>
    );
  };

  return (
    <div>
      <IfUserIs signedIn>
        <Module>
          <p className="my-4">
            Post a comment using the text box below. Be nice - no bad language,
            and don&apos;t spam the site. Do not post any personal information
            or any video chat links. Inappropriate posts will be removed and
            your account may be banned. The author of this page can hide any
            post in this thread.
          </p>
          <Textarea
            id="postEditor"
            className="max-w-sm"
            ref={textareaRef}
            placeholder="Add a comment"
            maxLength={2000}
          />
        </Module>
        <ButtonBar>{getPostButton()}</ButtonBar>
      </IfUserIs>
      <IfUserIs not signedIn>
        <Module>
          <p>Please sign in to post a comment</p>
        </Module>
      </IfUserIs>
    </div>
  );
};

export default PostEditor;
