import React, { FC, Fragment } from "react";

import {
  Animation,
  AnimationReactionSummary,
  ReactionType,
} from "@/gql/generated/graphql";

import Reaction from "../reaction";

import "./reactions.scss";

const REACTION_TYPES = ["dizzy", "laugh", "love", "stars"];

type ReactionsProps = {
  comments?: number;
  reactions: Pick<
    AnimationReactionSummary,
    "dizzy" | "laugh" | "love" | "stars"
  >;
  type?: "large" | null;
};

const Reactions: FC<ReactionsProps> = (props) => {
  const getComments = () => {
    if (!props.comments) return null;

    return (
      <span className="reaction reaction--comments">
        <i className="fas fa-comments" /> {props.comments}
      </span>
    );
  };

  const getReactions = () => {
    if (!props.reactions) return null;
    return REACTION_TYPES.map((reactionType) => {
      if (
        reactionType !== ReactionType.Dizzy &&
        reactionType !== ReactionType.Laugh &&
        reactionType !== ReactionType.Love &&
        reactionType !== ReactionType.Stars
      ) {
        return null;
      }

      const reaction = props.reactions[reactionType];
      if (!reaction) return null;
      return (
        <span
          key={reactionType}
          className={`reaction ${
            props.type === "large" ? "reaction--large" : ""
          }`}
        >
          <Reaction type={reactionType} />
          &nbsp;
          <span className="reaction-count">
            {props.reactions[reactionType]}
          </span>
        </span>
      );
    });
  };

  if (!props.reactions && !props.comments) return null;
  return (
    <Fragment>
      {getReactions()}
      {getComments()}
    </Fragment>
  );
};

export default Reactions;
