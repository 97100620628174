"use client";
import React, { FC, Fragment, ReactNode } from "react";

import { useCurrentUser } from "../user-context-provider/user-context-provider";

type IfUserIsProps = {
  admin?: boolean;
  userId?: string;
  children: ReactNode;
  not?: boolean;
  signedIn?: boolean;
};

const IfUserIs: FC<IfUserIsProps> = ({
  admin,
  userId,
  children,
  not,
  signedIn,
}) => {
  const currentUser = useCurrentUser();
  const isAdmin = currentUser?.roles?.includes("admin") ?? false;

  let show = false;

  if (admin && isAdmin) show = true;
  if (userId && userId === currentUser?.id) show = true;
  if (signedIn && currentUser) show = true;

  if (not) show = !show;

  if (show) {
    return <Fragment>{children}</Fragment>;
  }
  return null;
};

export default IfUserIs;
