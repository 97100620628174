"use client";
import { useFormStatus } from "react-dom";

import Button from "../button";

type SubmitButtonProps = {
  icon: string;
  rightAlign?: boolean;
  text: string;
  submittingText: string;
};

export default function SubmitButton({
  icon,
  rightAlign,
  text,
  submittingText,
}: SubmitButtonProps) {
  const status = useFormStatus();

  if (status.pending) {
    return (
      <Button
        icon="fas fa-spin fa-spinner"
        disabled
        rightAlign={rightAlign}
        type="positive"
        href="/"
      >
        {submittingText}
      </Button>
    );
  }

  return (
    <Button icon={icon} submit rightAlign={rightAlign} type="positive" href="/">
      {text}
    </Button>
  );
}
