import React, { useRef, useState } from "react";
import { useMutation } from "@apollo/client";

import Button from "@/components/button";
import { TextInput } from "@/components/forms";
import { ButtonGroup } from "@/components/layouts/button-group";
import UserError from "@/components/user-error";
import { useModal } from "@/hooks/use-modal";

import { MUTATE_CREATE_ANIMATION_FOLDER } from "../../../../gql/queries/mutate-create-animation-folder";
import { ButtonBar, Module } from "../../../layouts";

const ERROR_MAP = {
  ERR_FOLDER_EXISTS: "This folder name already exists",
  ERR_FOLDER_INVALID: "This folder name is not valid",
  ERR_FOLDER_TOO_LONG: "The folder name must be less than 30 characters",
};

const ManageFoldersCreate = () => {
  const { closeModal } = useModal();
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [createFolder] = useMutation(MUTATE_CREATE_ANIMATION_FOLDER, {
    onCompleted: closeModal,
    onError: (e) => {
      if (Array.isArray(e?.graphQLErrors[0]?.extensions?.errorCodes)) {
        setErrors(
          e?.graphQLErrors[0]?.extensions?.errorCodes.map(
            (code: keyof typeof ERROR_MAP) => ERROR_MAP[code],
          ),
        );
      }
    },
    refetchQueries: ["GetMyStudio"],
  });

  const onCreateClicked = () => {
    if (!inputRef?.current?.value) return;
    createFolder({
      variables: {
        name: inputRef?.current?.value,
      },
    });
  };

  return (
    <div>
      <Module>
        <p>
          Folder names are up to 30 characters long and contain only letters,
          numbers, dashes and underscores.
        </p>
      </Module>
      <Module>
        <UserError errors={errors} />
        <TextInput
          id="folderName"
          type="text"
          name="folder-name"
          label="Folder name:"
          ref={inputRef}
        />
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={closeModal}
            type="negative"
          >
            Cancel
          </Button>
          <Button
            icon="fas fa-folder-plus"
            onClick={onCreateClicked}
            type="positive"
          >
            Create folder
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default ManageFoldersCreate;
