import React from "react";
import { useRouter } from "next/navigation";

import Button from "@/components/button";
import { AnimationType, PixelMode } from "@/gql/generated/graphql";
import { useModal } from "@/hooks/use-modal";
import * as editorActions from "@/lib/state/actions/editor-actions";
import { useAppDispatch } from "@/lib/state/store";

import { ButtonBar, Module } from "../../layouts";
import { ButtonGroup } from "../../layouts/button-group";

import ModalTitle from "./modal-title";

export default function ConfirmCreateAvatarModal() {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { closeModal } = useModal();

  const onCreateClicked = async () => {
    await dispatch(
      editorActions.createNew({
        width: 128,
        height: 128,
        pixelMode: PixelMode.Pixelated,
        type: AnimationType.Avatar,
      }),
    );
    router.push("/editor");
    closeModal();
  };

  const onCancelClicked = () => {
    closeModal();
  };

  return (
    <div>
      <ModalTitle>Create Profile Picture</ModalTitle>
      <Module>
        <p>This will create a new animation in the editor.</p>
        <p>Any unsaved work will be lost. Do you want to continue?</p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-plus-circle"
            onClick={onCreateClicked}
            type="positive"
          >
            Create Profile Picture
          </Button>
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
