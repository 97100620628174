import { EnhancedStore, ThunkAction, UnknownAction } from "@reduxjs/toolkit";
import { v1 as uuidv1 } from "uuid";

import { AnimationType, PixelMode } from "@/gql/generated/graphql";
import { loadAll, reset, sync } from "@/lib/animation-db";
import { getBlankImage } from "@/lib/canvas/blank-image";
import { FrameImage } from "@/lib/canvas/frame-image";
import {
  EDITOR_DB_SAVE_STATE,
  EDITOR_DB_STATE,
} from "@/lib/state/reducers/editor-reducer";

import { AnimationState } from "../reducers/animation-default-state";
import { AppDispatch, AppThunk, RootState } from "../store";

import * as editorActions from "./editor-actions";

const loadFromStorage = function () {
  return async () => {
    const animationData = await loadAll();

    if (animationData) {
      return animationData;
    } else {
      return false;
    }
  };
};

const addFrame = function (index: number, delay: number): AppThunk<void> {
  return (dispatch) => {
    dispatch({
      type: "ANIMATION/ADD_FRAME",
      payload: {
        index,
        delay,
      },
    });
  };
};

const deleteFrame = function (index: number): AppThunk<void> {
  return (dispatch, getState) => {
    const { sequence } = getState().animation;

    if (sequence.length === 1) {
      dispatch(addFrame(1, sequence[0].delay));
    }

    dispatch({
      type: "ANIMATION/DELETE_FRAME",
      payload: {
        index,
      },
    });
  };
};

const updateFrame = function (
  index: number,
  canvas: HTMLCanvasElement,
  id?: string,
): AppThunk<void> {
  return (dispatch, getState) => {
    const imageToStore = new FrameImage({ from: canvas, id });

    const { currentLayerIndex } = getState().editor;

    dispatch({
      type: "ANIMATION/UPDATE_FRAME",
      payload: {
        index,
        image: imageToStore,
        layer: currentLayerIndex,
      },
    });
  };
};

const updateCurrentFrame = (
  canvas: HTMLCanvasElement,
  id: string,
): AppThunk<void> => {
  return (dispatch, getState) => {
    const { currentFrameIndex } = getState().editor;
    return dispatch(updateFrame(currentFrameIndex, canvas, id));
  };
};

export const setFrameDelayAction = function (
  index: number,
  delay: number,
  applyAll: boolean,
): AppThunk<void> {
  return (dispatch) => {
    dispatch({
      type: "ANIMATION/SET_FRAME_DELAY",
      payload: {
        index: index,
        delay,
        applyAll,
      },
    });
  };
};

const addFromFrame = function (
  sourceIndex: number,
  destinationIndex: number,
  layersToCopy: boolean[],
): AppThunk<void> {
  return (dispatch, getState) => {
    const frame = getState().animation.sequence[sourceIndex];
    const { animation } = getState();

    const layers = layersToCopy.map((shouldCopy, index) => {
      if (shouldCopy) {
        return {
          id: uuidv1(),
          image: frame.layers[index].image,
        };
      } else {
        return {
          id: uuidv1(),
          image: getBlankImage({
            width: animation.width,
            height: animation.height,
          }),
        };
      }
    });

    dispatch({
      type: "ANIMATION/ADD_FROM_FRAME",
      payload: {
        sourceIndex,
        destinationIndex,
        layers,
      },
    });
  };
};

const replaceFrame = function (
  sourceIndex: number,
  destinationIndex: number,
  layersToCopy: boolean[],
): AppThunk<void> {
  return (dispatch, getState) => {
    const sourceFrame = getState().animation.sequence[sourceIndex];
    const destinationFrame = getState().animation.sequence[destinationIndex];

    const layers = layersToCopy.map((shouldCopy, index) => {
      if (shouldCopy) {
        return {
          id: uuidv1(),
          image: sourceFrame.layers[index].image,
        };
      } else {
        return {
          id: uuidv1(),
          image: destinationFrame.layers[index].image,
        };
      }
    });

    dispatch({
      type: "ANIMATION/REPLACE_FRAME",
      payload: {
        sourceIndex,
        destinationIndex,
        layers,
      },
    });
  };
};

const replaceAllFrames = function (
  sourceIndex: number,
  layersToCopy: boolean[],
): AppThunk<void> {
  return (dispatch, getState) => {
    const allFrames = getState().animation.sequence;

    allFrames.forEach((frame, index) => {
      dispatch(replaceFrame(sourceIndex, index, layersToCopy));
    });
  };
};

const set = function (animationProps: AnimationState): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({
      type: "ANIMATION/SET",
      payload: { ...animationProps },
    });
    await sync(animationProps);
  };
};

const setSaved = function (isSaved: boolean): AppThunk<void> {
  return (dispatch) => {
    dispatch({
      type: "ANIMATION/SET_SAVED",
      payload: isSaved,
    });
  };
};

const setId = function (id: number): AppThunk<void> {
  return (dispatch) => {
    dispatch({
      type: "ANIMATION/SET_ID",
      payload: id,
    });
  };
};

const saveLocally = function (): AppThunk<void> {
  return async (dispatch, getState) => {
    const supportedState = getState().editor.databaseState;
    const { animation } = getState();
    if (supportedState !== EDITOR_DB_STATE.SUPPORTED) {
      console.log("unsupported db");
      return;
    }
    dispatch(editorActions.setDbSaveState(EDITOR_DB_SAVE_STATE.PENDING));

    await sync(animation);

    dispatch(editorActions.setDbSaveState(EDITOR_DB_SAVE_STATE.SAVED));
  };
};

const createNew = function (options: {
  width: number;
  height: number;
  pixelMode: PixelMode;
  type: AnimationType;
}): AppThunk<Promise<void>> {
  return async (dispatch) => {
    await reset();
    dispatch({
      type: "ANIMATION/NEW",
      payload: { ...options },
    });
    dispatch(addFrame(0, 100));
  };
};

const setUrl = function (newUrl: string) {
  return {
    type: "ANIMATION/SET_URL",
    payload: { url: newUrl },
  };
};

const setAnimationTitle = function (newTitle: string): AppThunk<void> {
  return (dispatch) => {
    dispatch({
      type: "ANIMATION/SET_TITLE",
      payload: { title: newTitle },
    });
  };
};

const setAnimationPublic = function (isPublic: boolean): AppThunk<void> {
  return (dispatch) => {
    dispatch({
      type: "ANIMATION/SET_PUBLIC",
      payload: { public: isPublic },
    });
  };
};

const setPixelMode = function (pixelMode: PixelMode): AppThunk<void> {
  return (dispatch) => {
    dispatch({
      type: "ANIMATION/SET_PIXEL_MODE",
      payload: { pixelMode },
    });
  };
};

const setAnimationFolder = function (folderId: string | null): AppThunk<void> {
  return (dispatch) => {
    dispatch({
      type: "ANIMATION/SET_FOLDER",
      payload: { folderId },
    });
  };
};

const setColourAction = function (
  index: number,
  colour: string,
): AppThunk<void> {
  return (dispatch) => {
    dispatch({
      type: "ANIMATION/SET_COLOUR",
      payload: { index, colour },
    });
  };
};

const renameLayer = ({ index, name }: { index: number; name: string }) => {
  return {
    type: "ANIMATION/LAYER_RENAME",
    payload: {
      index,
      name,
    },
  };
};

const addLayer = (): AppThunk<void> => {
  return (dispatch, getState) => {
    dispatch({
      type: "ANIMATION/LAYER_ADD",
    });
    dispatch(
      editorActions.setLayerVisibility({
        layerIndex: getState().animation.layers.length - 1,
        visible: true,
      }),
    );
    dispatch(
      editorActions.setFramePickerLayerVisibility({
        layerIndex: getState().animation.layers.length - 1,
        visible: false,
      }),
    );
  };
};

const deleteLayer = ({ index }: { index: number }): AppThunk<void> => {
  return (dispatch, getState) => {
    // const { sequence } = getState().animation;
    const { currentLayerIndex } = getState().editor;
    // const deletedLayerImages: Record<string, boolean> = {};

    // sequence.forEach((frame) => {
    //   deletedLayerImages[frame.layers[index].imageId] = true;
    // });

    if (currentLayerIndex >= index) {
      dispatch(editorActions.previousLayer());
    }

    dispatch({
      type: "ANIMATION/LAYER_DELETE",
      payload: {
        index,
      },
    });

    dispatch(editorActions.resetEditorLayers());
  };
};

export {
  addFrame,
  addFromFrame,
  addLayer,
  createNew,
  deleteFrame,
  deleteLayer,
  loadFromStorage,
  renameLayer,
  replaceAllFrames,
  replaceFrame,
  saveLocally,
  set,
  setAnimationFolder,
  setAnimationPublic,
  setAnimationTitle,
  setColourAction,
  setId,
  setPixelMode,
  setSaved,
  setUrl,
  updateCurrentFrame,
  updateFrame,
};
