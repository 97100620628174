const workingCanvas =
  typeof document !== "undefined" && document.createElement("canvas");
const workingCtx = workingCanvas && workingCanvas.getContext("2d");

export const getBlankImageCanvas = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  const emptyCanvas = document.createElement("canvas");
  emptyCanvas.width = width;
  emptyCanvas.height = height;

  return emptyCanvas;
};

export const dataUrlToImg = async (dataUrl: string) => {
  const loadedImage = await new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject(new Error("Could not load image from data URL"));
    };
    img.src = dataUrl;
  });

  return loadedImage;
};

export const calculateThumbDimensions = (
  originalWidth: number,
  originalHeight: number,
  maxWidth: number,
  maxHeight: number,
) => {
  var ratio = Math.min(maxWidth / originalWidth, maxHeight / originalHeight);

  return { width: originalWidth * ratio, height: originalHeight * ratio };
};

export const getThumbnail = (canvas: HTMLCanvasElement | HTMLImageElement) => {
  if (!workingCanvas || !workingCtx) return;

  const thumbDimensions = calculateThumbDimensions(
    canvas.width,
    canvas.height,
    240,
    180,
  );

  workingCanvas.width = thumbDimensions.width;
  workingCanvas.height = thumbDimensions.height;
  workingCtx.clearRect(0, 0, workingCanvas.width, workingCanvas.height);
  workingCtx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    thumbDimensions.width,
    thumbDimensions.height,
  );

  return workingCanvas.toDataURL();
};

export const rgbToHex = (r: number, g: number, b: number) => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export const moveWrapped = ({
  sourceCtx,
  destinationCtx,
  moveX,
  moveY,
}: {
  sourceCtx: CanvasRenderingContext2D;
  destinationCtx: CanvasRenderingContext2D;
  moveX: number;
  moveY: number;
}) => {
  const { canvas } = sourceCtx;

  destinationCtx.putImageData(
    sourceCtx.getImageData(
      0,
      0,
      sourceCtx.canvas.width,
      sourceCtx.canvas.height,
    ),
    0,
    0,
  );

  if (moveX === 0 && moveY === 0) return;

  if (moveX < 0 && 0 - moveX !== destinationCtx.canvas.width) {
    const imageSlice = destinationCtx.getImageData(
      0,
      0,
      0 - moveX,
      canvas.height,
    );
    const imageRemainder = destinationCtx.getImageData(
      0 - moveX,
      0,
      canvas.width + moveX,
      canvas.height,
    );

    destinationCtx.putImageData(imageRemainder, 0, 0);
    destinationCtx.putImageData(imageSlice, canvas.width + moveX, 0);
  } else if (moveX > 0 && canvas.width - moveX > 0) {
    const imageSlice = destinationCtx.getImageData(
      canvas.width - moveX,
      0,
      moveX,
      canvas.height,
    );
    const imageRemainder = destinationCtx.getImageData(
      0,
      0,
      canvas.width - moveX,
      canvas.height,
    );

    destinationCtx.putImageData(imageRemainder, moveX, 0);
    destinationCtx.putImageData(imageSlice, 0, 0);
  }

  if (moveY < 0 && 0 - moveY !== destinationCtx.canvas.height) {
    const imageSlice = destinationCtx.getImageData(
      0,
      0,
      canvas.width,
      0 - moveY,
    );
    const imageRemainder = destinationCtx.getImageData(
      0,
      0 - moveY,
      canvas.width,
      canvas.height + moveY,
    );

    destinationCtx.putImageData(imageRemainder, 0, 0);
    destinationCtx.putImageData(imageSlice, 0, canvas.height + moveY);
  } else if (moveY > 0 && canvas.height - moveY > 0) {
    const imageSlice = destinationCtx.getImageData(
      0,
      canvas.height - moveY,
      canvas.width,
      moveY,
    );
    const imageRemainder = destinationCtx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height - moveY,
    );

    destinationCtx.putImageData(imageRemainder, 0, moveY);
    destinationCtx.putImageData(imageSlice, 0, 0);
  }
};
