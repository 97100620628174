"use client";
import {
  ComponentProps,
  createContext,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

type ModalContextProps = {
  children: ReactNode;
};

interface ModalContextValue {
  state: ReactNode | null;
  setState: React.Dispatch<SetStateAction<ReactNode | null>>;
}

export const ModalContext = createContext<ModalContextValue>({
  state: null,
  setState: () => {},
});

export default function ModalProvider({ children }: ModalContextProps) {
  const [state, setState] = useState<ReactNode | null>(null);

  return (
    <ModalContext.Provider value={{ state, setState }}>
      {children}
    </ModalContext.Provider>
  );
}
