import React, { ComponentProps, FC, Fragment } from "react";
import dayjs from "dayjs";

import { Avatar } from "@/components";
import { User } from "@/gql/generated/graphql";

type PostUserProps = {
  user: Pick<User, "roles" | "badgeExpires" | "badge" | "name"> &
    ComponentProps<typeof Avatar>["user"];
};

export const PostUser: FC<PostUserProps> = ({ user }) => {
  let nameSuffix;

  if (user.roles.includes("admin")) {
    nameSuffix = <i className="fas fa-star" />;
  } else if (user.roles.includes("bot")) {
    nameSuffix = <i className="fas fa-robot" />;
  } else if (user.badgeExpires && dayjs(user.badgeExpires).isAfter(dayjs())) {
    if (user.badge === "WINNER") {
      nameSuffix = <i className="fas fa-trophy" />;
    } else if (user.badge === "MEDAL") {
      nameSuffix = <i className="fas fa-medal" />;
    }
  }

  return (
    <Fragment>
      <span className="inline-block relative h-8 aspect-square mr-1">
        <Avatar user={user} className="rounded-tl-md" />
      </span>{" "}
      <span className="group-hover:underline inline-block mx-1">
        {user.name}
      </span>
      {nameSuffix ? <Fragment> {nameSuffix}</Fragment> : null}
    </Fragment>
  );
};
