import { graphql } from "../generated";

export const MUTATE_CREATE_ANIMATION_FOLDER = graphql(`
  mutation MutateCreateAnimationFolder($name: String!) {
    createAnimationFolder(name: $name) {
      id
      name
    }
  }
`);
