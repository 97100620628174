import { graphql } from "../generated";

export const GET_FOLDER_QUERY = graphql(`
  query GetFolders {
    animationFolders {
      id
      name
    }
  }
`);
