import React, { FC, ReactNode } from "react";

import { User } from "@/gql/generated/graphql";

import { backgroundGradient } from "../../lib/css/background-gradient";

const DEFAULT_BG1 = "rgb(10, 147, 39)";
const DEFAULT_BG2 = "rgb(235, 252, 123)";

type ThemedPageProps = {
  theme?: User["theme"];
  children: ReactNode;
};

const ThemedPage: FC<ThemedPageProps> = ({ theme = {}, children }) => {
  return (
    <>
      <div
        className="relative sm:px-4"
        style={{
          ...backgroundGradient(
            theme?.bg1 || DEFAULT_BG1,
            theme?.bg2 || DEFAULT_BG2,
          ),
          width: "100vw",
        }}
      >
        {children}
      </div>
      <footer className="w-full bg-white bg-opacity-80 py-5 text-xs">
        <div className="mx-auto my-4 max-w-4xl px-2 sm:px-4 flex justify-between">
          <div>
            By <a href="https://madebylantern.co.uk/">Lantern Web Limited</a>
            <pre>v{process.env.VERSION}</pre>
          </div>
          <div className="text-right">
            <a href="/terms">Terms</a> | <a href="/privacy">Privacy</a> |{" "}
            <a href="/schools">Animation for schools</a> |{" "}
            <a href="/report-a-problem">Report a problem</a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ThemedPage;
