import React, { FC } from "react";
import Image from "next/image";

import {
  Animation,
  AnimationListItemFieldsFragment,
  AnimationType,
  CoreAnimationFieldsFragment,
} from "@/gql/generated/graphql";
import config from "@/lib/config";

type AnimationFileListProps = {
  animations: AnimationListItemFieldsFragment[];
  onAnimationSelected: (animationUrl: string) => void;
};

const AnimationFileList: FC<AnimationFileListProps> = ({
  animations,
  onAnimationSelected,
}) => {
  const getThumbnailPath = (animation: AnimationListItemFieldsFragment) => {
    if (!animation.url) return "";
    return animation.schemaVersion > 1
      ? `${config.s3Path}/${animation.url}/thumb.png?v=${animation.version}`
      : `${config.s3Path}/${animation.url}/0.png?v=${animation.version}`;
  };

  const renderItems = animations.map((animation) => {
    const typeSuffix =
      animation.type === AnimationType.Avatar ? (
        <span className="text-gray-500 text-xs ml-1 italic">Profile pic</span>
      ) : null;

    return (
      <tr
        key={animation.id}
        className="border border-gray-200 odd:bg-white even:bg-gray-100 group cursor-pointer"
        onClick={() => onAnimationSelected(animation.url)}
      >
        <td className="relative w-20 p-2 group-hover:bg-orange-300">
          <Image
            src={getThumbnailPath(animation)}
            className="shadow-md object-contain"
            alt=""
            fill
          />
        </td>
        <td className="p-2 group-hover:bg-orange-300">
          {animation.title}
          {typeSuffix}
        </td>
      </tr>
    );
  });

  return <table className="w-full">{renderItems}</table>;
};

export default AnimationFileList;
