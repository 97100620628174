import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import Button from "@/components/button";
import { ButtonBar, Module } from "@/components/layouts";
import { ButtonGroup } from "@/components/layouts/button-group";
import { AnimationFolder } from "@/gql/generated/graphql";
import { GET_FOLDER_QUERY } from "@/gql/queries/animation-folder";
import { MUTATE_DELETE_ANIMATION_FOLDER } from "@/gql/queries/mutate-delete-animation-folder";
import { useModal } from "@/hooks/use-modal";

const ManageFoldersDelete = () => {
  const { closeModal } = useModal();
  const { data, loading, error } = useQuery(GET_FOLDER_QUERY);

  const [deleteFolder] = useMutation(MUTATE_DELETE_ANIMATION_FOLDER, {
    onCompleted: closeModal,
    onError: () => {
      // TODO: handle error
    },
    refetchQueries: ["GetMyStudio"],
  });

  const renderFolders = (folders: Omit<AnimationFolder, "userId">[]) => {
    return folders.map((folder) => {
      return (
        <Button
          className="m-1"
          key={folder.id}
          icon="fas fa-folder-minus"
          type="negative"
          onClick={() => {
            deleteFolder({
              variables: {
                id: folder.id,
              },
            });
          }}
        >
          {folder.name}
        </Button>
      );
    });
  };

  return (
    <div>
      <Module>
        <p>
          Choose a folder to delete. Animations will be moved out and will not
          be deleted.
        </p>
      </Module>
      <Module>
        {!loading &&
          !error &&
          Array.isArray(data?.animationFolders) &&
          renderFolders(data.animationFolders)}
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={closeModal}
            type="negative"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default ManageFoldersDelete;
