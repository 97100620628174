import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import Button from "@/components/button";
import { Textarea } from "@/components/forms";
import { ButtonBar, Module } from "@/components/layouts";
import { ButtonGroup } from "@/components/layouts/button-group";
import UserError from "@/components/user-error";
import { MUTATE_POST } from "@/gql/queries/mutate-post";
import { POST } from "@/gql/queries/post";
import { useModal } from "@/hooks/use-modal";

import ModalTitle from "./modal-title";

type EditPostModalProps = {
  postId: number;
};

const EditPost = ({ postId }: EditPostModalProps) => {
  const { closeModal } = useModal();
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [error, setError] = useState(false);

  const [mutatePost] = useMutation(MUTATE_POST);

  const { data } = useQuery(POST, { variables: { postId: postId } });

  const onCancelClicked = () => {
    closeModal();
  };

  const onPostClicked = async () => {
    if (!textInputRef?.current?.value.trim()) {
      setError(true);
      return;
    }

    await mutatePost({
      variables: {
        id: postId,
        raw: textInputRef.current.value,
      },
    });
    closeModal();
  };

  return (
    <div>
      <ModalTitle>Edit Post</ModalTitle>
      {error && <UserError errors={["Please enter some post text"]} />}
      <Module>
        <Textarea
          ref={textInputRef}
          label="Edit your post text"
          id="thread-post-textarea"
          maxLength={2000}
          defaultValue={data?.post?.raw}
        />
      </Module>

      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCancelClicked}
            type="negative"
          >
            Cancel
          </Button>
          <Button icon="fas fa-edit" onClick={onPostClicked} type="positive">
            Save
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
};

export default EditPost;
