import React, { FC, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";

import {
  Animation,
  CompetitionVoteAction,
  CoreAnimationFieldsFragment,
  GetThreadQuery,
  Post,
  PostsFieldsFragment,
  Thread,
} from "@/gql/generated/graphql";
import { MUTATE_COMPETITION_VOTE } from "@/gql/queries/mutate-competition-vote";
import randomSort from "@/lib/random-sort";

import ThreadAnimationEntity from "./thread-animation-entity";

type ThreadCompetitionEntriesProps = {
  thread: NonNullable<GetThreadQuery["thread"]>;
};

const ThreadCompetitionEntries: FC<ThreadCompetitionEntriesProps> = ({
  thread,
}) => {
  const [entries, setEntries] = useState<CoreAnimationFieldsFragment[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  // const { showModal } = useModal();
  const [mutateCompetitionVote] = useMutation(MUTATE_COMPETITION_VOTE, {
    onError: (e) => {
      // const { code } = e.graphQLErrors[0].extensions;
      // switch (code) {
      //   case "USER_UNVERIFIED":
      //     showModal("UserUnverifiedModal");
      //     break;
      // }
    },
  });

  const { voteSummary } = thread;
  const userVotes = thread?.voteSummary?.userVotes ?? [];

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    interval = setInterval(() => {
      const hasTimeLeft = !dayjs().isAfter(dayjs(thread.endDate));
      if (hasTimeLeft !== isOpen) {
        setIsOpen(hasTimeLeft);
      }
    }, 1000);

    return function cleanup() {
      clearInterval(interval);
    };
  }, [isOpen, thread.endDate]);

  useEffect(() => {
    const entries: CoreAnimationFieldsFragment[] = [];
    const foundAnimationIds: Record<string, boolean> = {};

    thread.posts.forEach((post) => {
      post.blocks.forEach((block) => {
        block.entities.forEach((entity) => {
          if (
            entity.__typename === "Animation" &&
            !foundAnimationIds[entity.url] &&
            entity.user.id === post.userId
          ) {
            entries.push({ ...entity });
            foundAnimationIds[entity.url] = true;
          }
        });
      });
    });

    setEntries(randomSort(entries));
  }, [thread.posts.length, thread.posts]);

  const onEntryVoted = (animationId: number) => {
    mutateCompetitionVote({
      variables: {
        animationId,
        threadId: thread.id,
        action: userVotes.includes(animationId)
          ? CompetitionVoteAction.Remove
          : CompetitionVoteAction.Add,
      },
    });
  };

  if (entries.length === 0 || !voteSummary) {
    return null;
  }

  return (
    <div className="thread-competition-entries bg-gray-200 rounded-lg text-center p-2 shadow-md">
      <h3>All entries (randomly ordered)</h3>
      <div className="flex flex-wrap justify-around items-center">
        {entries.map((entry) => {
          const voteSummaryEntry = voteSummary.animationVotes.find(
            (voteEntry) => voteEntry.animationId === entry.id,
          );

          const votes = voteSummaryEntry ? voteSummaryEntry.votes : 0;

          return (
            <ThreadAnimationEntity
              key={entry.url}
              animation={entry}
              voteButton={isOpen}
              onVotePressed={onEntryVoted}
              voted={userVotes.includes(entry.id)}
              votes={votes}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ThreadCompetitionEntries;
