import { clear, del, get, getMany, set, setMany } from "idb-keyval";
import { v1 as uuidv1 } from "uuid";

import { FrameImage } from "@/lib/canvas/frame-image";
import { addBreadcrumb } from "@/lib/error-logger";
import { AnimationState } from "@/lib/state/reducers/animation-default-state";
import { RootState } from "@/lib/state/store";

type UnserialisedImageReference = string;

type UnserialisedAnimationFrame = {
  id: string;
  layers: UnserialisedImageReference[];
  delay: number;
};

const splitImagesFromSequence = (sequence: AnimationState["sequence"]) => {
  const images: Record<string, FrameImage> = {};
  const splitSequence = sequence.map((frame) => {
    return {
      ...frame,
      layers: frame.layers.map((layer) => {
        const { image } = layer;
        if (!images[image.id]) {
          images[image.id] = layer.image;
        }
        return image.id;
      }),
    };
  });

  return { images, splitSequence };
};

export const reset = async () => {
  return await clear();
};

export const writeSequence = async (sequence: AnimationState["sequence"]) => {
  console.time("Local DB: elapsed time");

  const { images, splitSequence } = splitImagesFromSequence(sequence);
  const existingIds: string[] = (await get("image-ids")) || [];
  const currentIds = Object.keys(images);

  // Clean out any old images
  const imageIdsToRemove = existingIds.filter((id) => !currentIds.includes(id));
  const removeText = `Removed [${imageIdsToRemove.length}]`;
  await Promise.all(imageIdsToRemove.map((imgId) => del(imgId)));

  // Add any new images
  const imageIdsToAdd = currentIds.filter((id) => !existingIds.includes(id));
  const imagesToAdd = imageIdsToAdd.map<[string, string]>((id) => {
    return [id, images[id].getData()];
  });

  if (imagesToAdd.length > 0) {
    await setMany(imagesToAdd);
  }

  const addText = `Added [${imageIdsToAdd.length}]`;
  await set("sequence", splitSequence);

  await set("image-ids", currentIds);
  console.log(`Local DB: ${removeText}, ${addText}`);
  console.timeEnd("Local DB: elapsed time");
};

export const testCompatibility = async () => {
  try {
    await del("_compat_test");
    const canvas = document.createElement("canvas");
    canvas.width = 400;
    canvas.height = 400;
    const ctx = canvas.getContext("2d")!;
    const data = ctx.getImageData(0, 0, 400, 400);
    await set("_compat_test", data);
    return true;
  } catch (e) {
    console.log("IDB not available");
    return false;
  }
};

export const sync = async (animationData: AnimationState) => {
  const { sequence, ...otherData } = animationData;
  await writeSequence(sequence);
  return await setMany(Object.entries(otherData));
};

export const loadAll = async (): Promise<AnimationState | null> => {
  const sequence: UnserialisedAnimationFrame[] | undefined =
    await get("sequence");
  if (Array.isArray(sequence)) {
    addBreadcrumb({
      category: "IDB",
      message: `Loaded sequence of length ${sequence.length}`,
    });
  } else {
    addBreadcrumb({
      category: "IDB",
      message: `No valid sequence found`,
    });
  }

  const images: Record<string, FrameImage> = {};
  const [
    palette,
    title,
    isPublic,
    saved,
    url,
    blacklisted,
    id,
    width,
    height,
    layers,
    pixelMode,
    type,
  ] = await getMany([
    "palette",
    "title",
    "public",
    "saved",
    "url",
    "blacklisted",
    "id",
    "width",
    "height",
    "layers",
    "pixelMode",
    "type",
  ]);

  if (!sequence || sequence.length === 0) return null;

  // Up-to-date sequence
  const imageIds: string[] | undefined = await get("image-ids");
  if (Array.isArray(imageIds)) {
    addBreadcrumb({
      category: "IDB",
      message: `Found imageIds of length ${imageIds.length}`,
    });
  } else {
    addBreadcrumb({
      category: "IDB",
      message: `No valid imageIds found`,
    });
    return null;
  }
  const imageData: string[] = await getMany(imageIds);

  await Promise.all(
    imageIds.map(async (id, index) => {
      images[id] = new FrameImage({ width, height, id });
      await images[id].setFromDataUrl(imageData[index]);
    }),
  );

  const sequenceWithImages = sequence.map((frame) => {
    return {
      ...frame,
      layers: frame.layers.map((unserialisedImageRef) => {
        return {
          id: uuidv1(),
          image: images[unserialisedImageRef],
        };
      }),
    };
  });

  return {
    sequence: sequenceWithImages,
    palette,
    title,
    saved,
    public: isPublic,
    url,
    blacklisted,
    id,
    width,
    height,
    layers,
    pixelMode,
    type,
    savedLocally: false,
    folderId: null,
  };
};
