import React, { Fragment } from "react";
import { useFormState, useFormStatus } from "react-dom";
import { useQuery } from "@apollo/client";

import Button from "@/components/button";
import { TextInput } from "@/components/forms";
import { useCurrentUser } from "@/components/user-context-provider/user-context-provider";
import UserError from "@/components/user-error";
import { useModal } from "@/hooks/use-modal";

import { GET_USER } from "../../../../gql/queries/user";
import { ButtonBar, Module } from "../../../layouts";
import { ButtonGroup } from "../../../layouts/button-group";

import { handleUpdateUsernameAction } from "./actions/update-username";

export type ChangeUsernameFormState = {
  errors: string[];
  completed: boolean;
};

const initialState: ChangeUsernameFormState = {
  errors: [],
  completed: false,
};

export default function UserDetailsUsername() {
  const currentUser = useCurrentUser();
  const { closeModal } = useModal();
  const [state, formAction] = useFormState(
    handleUpdateUsernameAction,
    initialState,
  );
  const { pending } = useFormStatus(); // FIXME: this must be in a child component - maybe submit button?

  const { loading, data } = useQuery(GET_USER, {
    variables: { id: currentUser?.id },
  });

  if (state.completed) closeModal();

  return (
    <div>
      {!loading && data?.user?.nameChangePermitted && (
        <form action={formAction}>
          <Module>
            <p>
              You can change your user name once within thirty days. The change
              is immediate. You will need to use the new username to sign in.
            </p>
            <p>
              Your name will update in this browser tab. If you have other
              browser tabs open on this site, you will need to sign out and sign
              in again to see the change.
            </p>
            <UserError errors={state.errors} />

            <TextInput
              label="User name: (No spaces, alphanumeric, less than 30 chars)"
              name="username"
              id="username"
              type="text"
              autoCapitalize="none"
              defaultValue={currentUser?.name}
            />
          </Module>
          <ButtonBar>
            <ButtonGroup align="right">
              <Button icon="fas fa-times" onClick={closeModal} type="negative">
                Cancel
              </Button>
              {!pending && (
                <Button icon="fas fa-user" submit type="positive" href="/">
                  Change username
                </Button>
              )}
            </ButtonGroup>
          </ButtonBar>
        </form>
      )}
      {!loading && !data?.user?.nameChangePermitted && (
        <Fragment>
          <Module>
            <p>
              You have changed or created your name within the last thirty days.
              You cannot change your name until thirty days have passed.
            </p>
          </Module>
          <ButtonBar>
            <ButtonGroup align="right">
              <Button icon="fas fa-times" onClick={closeModal} type="negative">
                Cancel
              </Button>
            </ButtonGroup>
          </ButtonBar>
        </Fragment>
      )}
    </div>
  );
}
