"use client";
import { createContext, ReactNode, useContext } from "react";

import { User } from "@/gql/generated/graphql";

export const UserContext = createContext<User | null>(null);

type UserContextProviderProps = {
  children: ReactNode;
  value: User | null;
};

export default function UserContextProvider({
  children,
  value,
}: UserContextProviderProps) {
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useCurrentUser = (): User | null => {
  const user = useContext(UserContext);
  return user;
};
