import React from "react";
import { useFormState } from "react-dom";
import { useMutation, useQuery } from "@apollo/client";

import Button from "@/components/button";
import { TextInput } from "@/components/forms";
import { ButtonBar, Module } from "@/components/layouts";
import { ButtonGroup } from "@/components/layouts/button-group";
import { useCurrentUser } from "@/components/user-context-provider/user-context-provider";
import UserError from "@/components/user-error";
import { MUTATE_SEND_VERIFICATION_EMAIL } from "@/gql/queries/mutate-send-verification-email";
import { GET_USER } from "@/gql/queries/user";
import { useModal } from "@/hooks/use-modal";

import VerificationEmailSentModal from "../verification-email-sent";

import { handleUpdateEmailAction } from "./actions/update-email";

export type ChangeEmailFormState = {
  errors: string[];
  completed: boolean;
};

const initialState: ChangeEmailFormState = {
  errors: [],
  completed: false,
};

export default function UserDetailsEmail() {
  const currentUser = useCurrentUser();
  const email = currentUser?.email;
  const { closeModal, openModal } = useModal();
  const [state, formAction] = useFormState(
    handleUpdateEmailAction,
    initialState,
  );

  const [mutateSendVerificationEmail] = useMutation(
    MUTATE_SEND_VERIFICATION_EMAIL,
    {
      onCompleted: () => {
        openModal(() => <VerificationEmailSentModal />);
      },
    },
  );

  const { loading, data } = useQuery(GET_USER, {
    variables: { id: currentUser?.id },
  });

  if (state.completed) {
    openModal(() => <VerificationEmailSentModal />);
  }

  return (
    <form action={formAction}>
      {!loading && !data?.user?.verified && (
        <Module>
          <p>
            Your email address has not been verified. Check the email address is
            correct below and re-send a verification email if necessary. The
            email will be sent to <strong>{email}</strong>, if this is not
            correct, please update below and press the &quot;update email&quot;
            button.
          </p>
          <Button
            icon="fas fa-envelope"
            onClick={() => {
              mutateSendVerificationEmail({
                variables: { id: currentUser?.id },
              });
            }}
            type="neutral"
          >
            Re-send verification email
          </Button>
        </Module>
      )}
      {!loading && data?.user?.verified && (
        <Module>
          <p>Your email address has been successfully verified. Thank you!</p>
        </Module>
      )}
      <Module>
        <UserError errors={state.errors} />
        <TextInput
          label="Email address:"
          id="email"
          name="email"
          type="email"
          autoCapitalize="none"
          defaultValue={email ?? ""}
        />
      </Module>
      <Module>
        <p>
          This must be a valid email address which can receive emails from The
          Animator. Accounts without verified email addresses cannot post
          animations or comments.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button icon="fas fa-times" onClick={closeModal} type="negative">
            Cancel
          </Button>
          <Button icon="fas fa-envelope" submit type="positive" href="/">
            Update email
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </form>
  );
}
