import React, { DragEvent, FC, MouseEvent, ReactNode } from "react";
import classNames from "classnames";
import Link from "next/link";

export const BASE_STYLES =
  "hover:no-underline px-2 py-1 border-2 text-sm leading-4 font-medium shadow-md focus:outline-none";

export function getButtonStyles({
  type = "neutral",
  active = false,
  rightAlign = false,
  centre = false,
  className = "",
}) {
  return classNames({
    [BASE_STYLES]: type !== "menu",
    "rounded-md bg-gray-100 border-gray-300 hover:bg-blue-600 hover:border-blue-600 hover:text-white focus:ring-blue-400 text-black focus:ring-2 focus:ring-offset-2":
      type === "neutral",

    "rounded-md bg-transparent hover:bg-blue-600 hover:border-blue-600 hover:text-white focus:ring-blue-400 text-black focus:ring-2 focus:ring-offset-2 border-transparent shadow-none":
      type === "transparent",

    "rounded-md bg-green-500 border-green-600 hover:bg-green-600 focus:ring-green-400 text-white focus:ring-2 focus:ring-offset-2":
      type === "positive",

    "rounded-md bg-yellow-400 border-yellow-300 hover:bg-yellow-300 focus:ring-yellow-400 text-black focus:ring-2 focus:ring-offset-2":
      type === "highlight",

    "rounded-md bg-red-500 border-red-600 hover:bg-red-600 focus:ring-red-400 text-white focus:ring-2 focus:ring-offset-2":
      type === "negative",

    "rounded-md bg-gray-300 border-gray-400 hover:bg-gray-400 focus:ring-gray-200 text-white focus:ring-2 focus:ring-offset-2":
      type === "inactive",

    "relative rounded-l-md focus:ring-1": type === "grouped-l",
    "border-gray-300 bg-gray-100 hover:bg-white":
      (type === "grouped-l" || type === "grouped-m" || type === "grouped-r") &&
      !active,

    "-ml-px relative focus:ring-1": type === "grouped-m",

    "-ml-px relative rounded-r-md focus:ring-1": type === "grouped-r",

    "bg-blue-500 border-blue-500 hover:bg-blue-400 text-white": active,

    "ml-auto": rightAlign,
    "m-auto": centre,

    "w-full group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900":
      type === "menu",
    [className]: true,
  });
}

interface BaseButtonProps {
  children: ReactNode;
  type?:
    | "neutral"
    | "transparent"
    | "positive"
    | "highlight"
    | "negative"
    | "menu"
    | "inactive"
    | "grouped-l"
    | "grouped-r"
    | "grouped-m";
  rightAlign?: boolean;
  centre?: boolean;
  active?: boolean;
  icon?: string;
  className?: string;
  disabled?: boolean;
  submit?: boolean;
  scroll?: boolean;
  onDragOver?: (e: DragEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onDragEnter?: (e: DragEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onDragLeave?: (e: DragEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onDrop?: (e: DragEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

interface ButtonPropsWithClick extends BaseButtonProps {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  href?: never;
}

interface ButtonPropsWithHref extends BaseButtonProps {
  onClick?: never;
  href: string;
}

export type ButtonProps = ButtonPropsWithClick | ButtonPropsWithHref;

const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    type = "neutral",
    rightAlign,
    centre,
    active = false,
    icon,
    className = "",
    disabled,
    onClick,
    href,
    submit,
    scroll = true,
    ...rest
  } = props;

  const classes = getButtonStyles({
    className,
    type,
    active,
    rightAlign,
    centre,
  });

  if (disabled) {
    return (
      <span
        className={
          BASE_STYLES +
          " rounded-md bg-gray-300 border-gray-300 text-white focus:ring-2 focus:ring-offset-2"
        }
      >
        {icon ? <i className={`${icon} mr-2`} /> : null}
        {children}
      </span>
    );
  }

  if (onClick) {
    return (
      <button className={classes} onClick={onClick} {...rest}>
        {icon ? <i className={`${icon} mr-2`} /> : null}
        {children}
      </button>
    );
  }

  if (submit) {
    return (
      <button className={classes} type="submit" {...rest}>
        {icon ? <i className={`${icon} mr-2`} /> : null}
        {children}
      </button>
    );
  }

  return (
    <Link className={classes} href={href} scroll={scroll} {...rest}>
      {icon ? <i className={`${icon} mr-2`} /> : null}
      {children}
    </Link>
  );
};

export default Button;
