import React, { ComponentProps } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Link from "next/link";

import IfUserIs from "@/components/if-user-is";
import {
  Post,
  PostsFieldsFragment,
  Thread,
  User,
} from "@/gql/generated/graphql";
import { textColorForBackground } from "@/lib/text-color-for-background";
import usernameThemeColour from "@/lib/user-name-theme-colour";

import { PostBlocks } from "./post-blocks";
import { PostControls } from "./post-controls";
import { PostUser } from "./post-user";

type ThreadPostsProps = {
  posts: PostsFieldsFragment[];
  threadUserId: string;
  threadType: Thread["type"];
};

dayjs.extend(relativeTime);

export const ThreadPosts = ({
  posts,
  threadUserId,
  threadType,
}: ThreadPostsProps) => {
  return posts.map((post, index) => {
    let themeColour = usernameThemeColour(post.user);

    const userStyle = {
      background: `${themeColour}`,
      color: textColorForBackground(themeColour),
      opacity: post.hidden ? 0.5 : 1,
      textOverflow: "ellipsis",
    };

    const contentStyle = {
      background: `linear-gradient(to bottom, rgba(255,255,255,0.9), rgba(255, 255, 255, 0.9)), ${themeColour}`,
      opacity: post.hidden ? 0.5 : 1,
    };

    const postClasses = classNames(
      "shadow-inner px-2 py-1 rounded-b-md",
      {
        "bg-white bg-opacity-70": index % 2 === 0,
      },
      {
        "bg-white bg-opacity-10": index % 2 === 1,
      },
    );

    return (
      <div key={post.id} id={`post${post.id}`} className="max-w-sm">
        <div className="max-w-full inline-block my-2">
          <div className="h-8 relative leading-8 flex">
            <Link
              href={`/studio/${post.user.name}`}
              className="relative rounded-t-md pr-2 py-0 h-8 inline-flex items-center hover:no-underline group overflow-hidden whitespace-nowrap"
              style={userStyle}
            >
              <PostUser user={post.user} />
            </Link>
            <div className="flex-initial whitespace-nowrap">
              <span
                className={`pl-2 py-0.5 inline-block text-sm text-gray-500 ${
                  post.hidden ? "opacity-50" : "opacity-100"
                }`}
              >
                {dayjs(post.createdAt).fromNow()}
              </span>
              <PostControls post={post} threadUserId={threadUserId} />
            </div>
          </div>
          <div
            className="break-words text-sm rounded-b-md shadow-md"
            style={contentStyle}
          >
            <div className={postClasses}>
              <IfUserIs admin>
                <PostBlocks threadType={threadType} post={post} />
              </IfUserIs>
              <IfUserIs not admin>
                {post.hidden ? (
                  "[hidden]"
                ) : (
                  <PostBlocks threadType={threadType} post={post} />
                )}
              </IfUserIs>
            </div>
          </div>
        </div>
      </div>
    );
  });
};
