import React, { ComponentProps } from "react";

import { ReactionType } from "@/gql/generated/graphql";

import { reactionsMap } from "../reaction-selector/constants";

type ReactionProps = ComponentProps<"i"> & {
  type: string;
};

const Reaction = function ({ type, ...otherProps }: ReactionProps) {
  if (
    type === ReactionType.Dizzy ||
    type === ReactionType.Laugh ||
    type === ReactionType.Love ||
    type === ReactionType.Stars
  ) {
    return <i className={reactionsMap[type]} {...otherProps} />;
  } else return null;
};

export default Reaction;
