import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const Module = ({ children, wide = false, light = false, className = "" }) => {
  const classes = classNames({
    "px-2 sm:px-4 my-4": !wide,
    "bg-white": light,
  });

  const innerClasses = classNames(
    {
      "p-4 sm:p-6": wide,
    },
    className,
  );

  return (
    <div className={classes}>
      <div className={innerClasses}>{children}</div>
    </div>
  );
};

Module.propTypes = {
  children: PropTypes.node.isRequired,
  wide: PropTypes.bool,
  light: PropTypes.bool,
  className: PropTypes.string,
};

export default Module;
