import React, { FC } from "react";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";

import DropdownMenu, {
  DropdownMenuEntry,
} from "@/components/dropdown-menu/dropdown-menu";
import { Post, PostsFieldsFragment, User } from "@/gql/generated/graphql";
import { MUTATE_HIDE_POST } from "@/gql/queries/mutate-post";
import { useModal } from "@/hooks/use-modal";

import EditPost from "../modal/components/edit-post";
import PostReportModal from "../modal/components/post-report";
import { useCurrentUser } from "../user-context-provider/user-context-provider";

type PostControlsProps = {
  post: PostsFieldsFragment;
  threadUserId: User["id"];
};

export const PostControls: FC<PostControlsProps> = ({ post, threadUserId }) => {
  const currentUser = useCurrentUser();
  const isAdmin = currentUser?.roles.includes("admin");
  const [mutatePost] = useMutation(MUTATE_HIDE_POST);
  const { openModal } = useModal();

  const onHidePostPressed = () => {
    mutatePost({
      variables: {
        hidden: !post.hidden,
        id: post.id,
      },
    });
  };

  const onReportClicked = () => {
    openModal(() => <PostReportModal postId={post.id} />);
  };

  const canEdit =
    isAdmin ||
    (post.userId === currentUser?.id &&
      dayjs(post.createdAt).add(10, "minutes").isAfter(dayjs()));

  const onReplyClicked = ({ quote = false } = {}) => {
    const postEditor = document.getElementById(
      "postEditor",
    ) as HTMLTextAreaElement;

    let replyText = "";

    if (quote) {
      replyText = `@${post.user.name} said:\n`;
      post.blocks.forEach((block) => {
        replyText += `> `;
        const replyEntities = block.entities.map((entity) => {
          if (entity.__typename === "PostText") {
            return entity.text;
          } else if (entity.__typename === "User") {
            return `@${entity.name}`;
          }
        });
        replyText += replyEntities.join("") + "\n";
      });
    } else {
      replyText = `@${post.user.name}\n`;
    }
    postEditor.value = replyText;
    postEditor.scrollIntoView();
    postEditor.focus();
  };

  // Can only hide non-admin posts
  const postedByAdmin = post.user.roles.includes("admin");
  const isThreadOwner = threadUserId === currentUser?.id;
  let menuItems: DropdownMenuEntry[] = [];

  if (currentUser && !post.hidden) {
    menuItems = menuItems.concat([
      {
        icon: "fas fa-reply",
        text: "Reply",
        onClick: () => onReplyClicked(),
      },
      {
        icon: "fas fa-quote-left",
        text: "Quote reply",
        onClick: () => onReplyClicked({ quote: true }),
      },
    ]);
  }

  if (canEdit) {
    menuItems.push({
      icon: "fas fa-edit",
      text: "Edit",
      onClick: () => openModal(() => <EditPost postId={post.id} />),
    });
  }

  menuItems.push({
    icon: "fas fa-flag",
    text: "Report",
    onClick: onReportClicked,
  });

  if (isAdmin || (isThreadOwner && !postedByAdmin)) {
    menuItems.push({
      icon: post.hidden ? "fas fa-eye" : "fas fa-eye-slash",
      text: post.hidden ? "Show" : "Hide",
      onClick: onHidePostPressed,
    });
  }

  return (
    <span className="thread-post__controls">
      <DropdownMenu
        button={{
          text: "",
          type: "transparent",
        }}
        items={menuItems}
      />
    </span>
  );
};
