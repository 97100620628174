import React, { FC } from "react";
import { useQuery } from "@apollo/client";

import { AnimationList, Button } from "@/components";
import { ButtonBar, ButtonGroup, Module } from "@/components/layouts";
import { PUBLIC_STUDIO } from "@/gql/queries/public-studio";
import { useModal } from "@/hooks/use-modal";

type DailyAnimationsModalProps = {
  username: string;
  date: string;
};

const DailyAnimationsModal = ({
  username,
  date,
}: DailyAnimationsModalProps) => {
  const { closeModal } = useModal();
  const onCloseClicked = () => {
    closeModal();
  };

  const { data = { publicAnimations: [] } } = useQuery(PUBLIC_STUDIO, {
    variables: {
      name: username,
      date: date,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <div>
      <ButtonBar>
        <ButtonGroup>
          <Button
            icon="fas fa-angle-right"
            href={`/studio/${username}`}
            type="positive"
          >
            More by {username}
          </Button>
        </ButtonGroup>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-times-circle"
            onClick={onCloseClicked}
            type="neutral"
          >
            Close
          </Button>
        </ButtonGroup>
      </ButtonBar>
      <Module>
        <AnimationList
          animations={data.publicAnimations}
          showPublic={false}
          onAnimationSelected={closeModal}
        />
      </Module>
    </div>
  );
};

export default DailyAnimationsModal;
