import { graphql } from "../generated";

export const PUBLIC_STUDIO = graphql(`
  query GetPublicStudio($offset: Int, $limit: Int, $name: String, $date: Date) {
    publicAnimations(offset: $offset, limit: $limit, name: $name, date: $date) {
      id
      url
      title
      version
      schemaVersion
      userId
      dateUpdated
      encoded
      pixelMode
      type
      altThumbnail
      reactions {
        love
        laugh
        dizzy
        stars
      }
      commentCount
    }
    publicAnimationsCount(name: $name)
    user(name: $name) {
      id
      name
      theme {
        bg1
        bg2
        post
        username
      }
      dateJoined
      ip
      badge
      badgeExpires
      banned
      aliases
      avatarUrl
    }
  }
`);
