import React, { useState } from "react";

import { Module } from "@/components/layouts";
import TabBar from "@/components/tab-bar/tab-bar";
import { useModal } from "@/hooks/use-modal";

import UserDetailsEmail from "./user-details/email";
import UserDetailsUsername from "./user-details/username";
import ModalTitle from "./modal-title";

export default function UserDetailsModal() {
  const [activeTab, setActiveTab] = useState("email");

  return (
    <div>
      <ModalTitle>My Account</ModalTitle>
      <Module>
        <TabBar
          selected={activeTab}
          onChange={(mode) => {
            setActiveTab(mode);
          }}
          options={[
            { id: "email", value: "email", label: "Email" },
            { id: "username", value: "username", label: "Username" },
          ]}
        />
      </Module>
      {activeTab === "email" && <UserDetailsEmail />}
      {activeTab === "username" && <UserDetailsUsername />}
    </div>
  );
}
