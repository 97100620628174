import React from "react";

import Button from "@/components/button";
import { useModal } from "@/hooks/use-modal";

import { ButtonBar, Module } from "../../layouts";
import { ButtonGroup } from "../../layouts/button-group";

import ModalTitle from "./modal-title";
import UserDetailsModal from "./user-details";

export default function UserUnverified() {
  const { openModal, closeModal } = useModal();

  return (
    <div className="text-center">
      <ModalTitle>Email Not Verified</ModalTitle>
      <Module>
        <p>
          To prevent abuse of this website, you must verify your email address
          before posting or creating animations in the public gallery. Please
          check your emails and click the link.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-user-cog"
            onClick={() => {
              openModal(() => <UserDetailsModal />);
            }}
            type="neutral"
            rightAlign
          >
            Check &amp; update email
          </Button>
          <Button
            icon="fas fa-check-circle"
            onClick={closeModal}
            type="positive"
            rightAlign
          >
            Ok
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
