import React, { FC, MouseEvent } from "react";

import Button from "@/components/button";

type TabBarOption = {
  id: string;
  value: string;
  label: string;
};

type TabBarProps = {
  onChange: (value: string) => void;
  options: TabBarOption[];
  selected: string;
};

const TabBar: FC<TabBarProps> = (props) => {
  const onChange = (e: MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.dataset["value"]) {
      props.onChange(e.currentTarget.dataset["value"]);
    }
  };

  return (
    <div className="text-center">
      {props.options.map((option, index) => {
        const icon =
          option.id === props.selected ? "fas fa-dot-circle" : "far fa-circle";

        const type =
          (index === 0 && "grouped-l") ||
          (index === props.options.length - 1 && "grouped-r") ||
          "grouped-m";

        return (
          <Button
            key={index}
            type={type}
            onClick={onChange}
            data-value={option.value}
            icon={icon}
            active={option.id === props.selected}
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  );
};

export default TabBar;
