"use client";

import React from "react";
import { useRouter } from "next/navigation";

import Button from "@/components/button";

const BackButton = () => {
  const router = useRouter();

  const onClick = () => {
    router.back();
  };

  return (
    <Button icon="fas fa-arrow-circle-left" onClick={onClick}>
      Back
    </Button>
  );
};

export default BackButton;
