import React, { ComponentProps } from "react";

type TextAreaProps = ComponentProps<"textarea"> & {
  className?: string;
  label?: string;
  id: string;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, placeholder, ...props }, ref) => {
    return (
      <div>
        {props.label && (
          <label
            className="block text-sm font-medium text-gray-700 my-2"
            htmlFor={props.id}
          >
            {props.label}
          </label>
        )}
        <textarea
          className={`rounded-md w-full shadow-inner p-2 h-40 border border-gray-300 ${className}`}
          ref={ref}
          placeholder={placeholder}
          {...props}
        />
      </div>
    );
  },
);

TextArea.displayName = "TextAreaa";

export default TextArea;
