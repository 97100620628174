import { FrameImage } from "./frame-image";

let instance: FrameImage;

export const getBlankImage = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  if (!instance || instance.width !== width || instance.height !== height) {
    const blankCanvas = document.createElement("canvas");
    blankCanvas.width = width;
    blankCanvas.height = height;
    instance = new FrameImage({
      from: blankCanvas,
      width,
      height,
      id: "_blank",
    });
  }
  return instance;
};
