import React, { FC, ReactNode } from "react";
import classNames from "classnames";

type ButtonGroupProps = {
  children: ReactNode;
  align?: "left" | "centre" | "right";
  expand?: boolean;
};

const ButtonGroup: FC<ButtonGroupProps> = ({
  children,
  align = "left",
  expand = false,
}) => {
  const classes = classNames({
    "justify-start": align === "left",
    "justify-center": align === "centre",
    "justify-end": align === "right",
    grow: expand,
    "flex-1": !expand,
  });

  return (
    <div
      className={`flex flex-row px-2 sm:px-4 space-x-2 items-start ${classes}`}
    >
      {children}
    </div>
  );
};

export { ButtonGroup };
