import { ReactNode, useCallback, useContext } from "react";

import { ModalContext } from "@/components/modal-provider";

export const useModal = () => {
  const { state, setState } = useContext(ModalContext);

  const openModal = useCallback(
    (componentFn: () => ReactNode) => {
      setState(componentFn);
    },
    [setState],
  );

  const closeModal = useCallback(() => {
    setState(null);
  }, [setState]);

  const isOpen = state !== null;

  return { openModal, closeModal, component: state, isOpen };
};
