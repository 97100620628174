import { graphql } from "../generated";

export const POST = graphql(`
  query GetPost($postId: Int!) {
    post(postId: $postId) {
      id
      threadId
      userId
      raw
      createdAt
      updatedAt
    }
  }
`);
