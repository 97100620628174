import React, { FC, ReactNode } from "react";
import classNames from "classnames";

import { User } from "../../../gql/generated/graphql";

const DEFAULT_BG = "rgb(235, 252, 123)";

type MainLayoutProps = {
  children: ReactNode;
  theme?: User["theme"];
  small?: boolean;
};

const Main: FC<MainLayoutProps> = ({ children, theme = {}, small = false }) => {
  const backgroundImage = theme?.bg2
    ? `linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), linear-gradient(${
        theme.bg2 || DEFAULT_BG
      }, ${theme.bg2 || DEFAULT_BG})`
    : "linear-gradient(rgb(249 235 214),rgb(249 235 214))";

  const classes = classNames("sm:rounded-lg mx-auto my-4 shadow-lg", {
    "max-w-4xl": !small,
    "max-w-md": small,
  });

  return (
    <main
      style={{
        backgroundImage,
      }}
      className={classes}
    >
      {children}
      <div className="border border-transparent" />
    </main>
  );
};

export default Main;
