"use client";
import React, {
  DragEvent,
  FC,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import Image from "next/image";
import Link from "next/link";

import Reactions from "@/components/reactions";
import { UserPill, UserPillUser } from "@/components/user-pill/user-pill";
import {
  Animation,
  AnimationListItemFieldsFragment,
  AnimationReactionSummary,
  AnimationType,
  CoreAnimationFieldsFragment,
  PixelMode,
  Reaction,
  User,
} from "@/gql/generated/graphql";
import { useModal } from "@/hooks/use-modal";
import config from "@/lib/config";

import DailyAnimationsModal from "../modal/components/daily-animations";

const MAX_STACK_SIZE = 2;

export type ListItemAnimation = AnimationListItemFieldsFragment & {
  public?: Animation["public"];
  dailyAnimationCount?: Animation["dailyAnimationCount"];
  user?: UserPillUser;
  altThumbnail?: string | null;
  commentCount?: number;
  dateUpdated?: string;
};

type AnimationListItemProps = {
  animation: ListItemAnimation;
  showPublic: boolean;
  user?: User;
  selectMode: boolean;
  selected: boolean;
  onAnimationSelected?: (id: Animation["id"]) => void;
};

export const AnimationListItem: FC<AnimationListItemProps> = ({
  animation,
  showPublic,
  user,
  selectMode,
  selected,
  onAnimationSelected,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const { openModal } = useModal();
  const userData = user ?? animation.user;

  const dailyAnimationCount = animation.dailyAnimationCount ?? 0;

  const getThumbnailPath = () => {
    if (!animation.url) return null;

    if (animation.altThumbnail) {
      return `${config.s3Path}${animation.altThumbnail}`;
    }

    return animation.schemaVersion > 1
      ? `${config.s3Path}/${animation.url}/thumb.png?v=${animation.version}`
      : `${config.s3Path}/${animation.url}/0.png?v=${animation.version}`;
  };

  const thumbPath = getThumbnailPath();

  useEffect(() => {
    if (imgRef.current && imgRef.current.naturalWidth > 0) {
      setLoaded(true);
    }
  }, []);

  const showEncoding = animation.url && !animation.encoded;
  const showSpinner = !loaded && !showEncoding;

  const renderStack = (stackSize: number) => {
    return Array.from(
      Array(stackSize < MAX_STACK_SIZE ? stackSize : MAX_STACK_SIZE).keys(),
    ).map((i) => <div className="animation-item__stack" key={i} />);
  };

  const onOpenDailyAnimations = (
    e: MouseEvent<HTMLAnchorElement>,
    username: string,
    date: string,
  ) => {
    e.preventDefault();
    openModal(() => <DailyAnimationsModal username={username} date={date} />);
  };

  const clickHandler = (e: MouseEvent<HTMLAnchorElement>) => {
    if (
      dailyAnimationCount > 1 &&
      animation?.user?.name &&
      animation.dateUpdated
    ) {
      onOpenDailyAnimations(e, animation.user.name, animation.dateUpdated);
    } else if (selectMode) {
      e.preventDefault();
      onAnimationSelected && onAnimationSelected(animation.id);
    } else if (onAnimationSelected) {
      onAnimationSelected(animation.id);
    }
  };

  const pagePart =
    animation.type === AnimationType.Standalone ? "animation" : "comic";

  const dragstartHandler = (ev: DragEvent<HTMLLIElement>) => {
    ev.dataTransfer.setData(
      "text/uri-list",
      `https://theanimator.co.uk/animation/${animation.url}`,
    );
    ev.dataTransfer.setData("text/animation-url", animation.url);
    ev.dataTransfer.setData("text/animation-id", `${animation.id}`);
    ev.dataTransfer.dropEffect = "move";
    setIsDragging(true);
    ev.dataTransfer.setDragImage(ev.currentTarget, 0, 0);
  };

  const dragendHandler = (ev: DragEvent<HTMLLIElement>) => {
    setIsDragging(false);
  };

  return (
    <li
      className={`animation-list__item ${
        selected ? "animation-list__item--selected" : ""
      } ${isDragging ? "opacity-20" : ""}`}
      draggable
      onDragStart={dragstartHandler}
      onDragEnd={dragendHandler}
    >
      {dailyAnimationCount > 1 ? renderStack(dailyAnimationCount - 1) : null}
      <div className="animation-list__item-inner">
        <Link
          href={animation.url ? `/${pagePart}/${animation.url}` : "#"}
          className="animation-list__item-link hover:no-underline"
          onClick={clickHandler}
        >
          {thumbPath && animation.encoded && (
            <Image
              // className={`animation-item__image ${showSpinner ? "hidden" : ""}`}
              className={`animation-item__image`}
              alt={animation.title}
              src={thumbPath}
              onLoad={() => setLoaded(true)}
              ref={imgRef}
              fill
              style={{
                imageRendering:
                  animation.pixelMode === PixelMode.Pixelated
                    ? "pixelated"
                    : "auto",
              }}
              sizes="(max-width: 539px) 450px, 260px"
            />
          )}

          {showSpinner && (
            <div className="animation-list__placeholder">
              <i className="fas fa-spin fa-spinner" />
            </div>
          )}

          {showEncoding && (
            <div className="animation-item__encoding text-center text-lg absolute inset-x-0 bottom-0 mb-8 text-gray-400">
              <i className="fas fa-cog fa-spin"></i>
              <div className="animation-item__encoding-text">Encoding...</div>
            </div>
          )}
        </Link>
        <div className="animation-item__title-bar text-sm p-1">
          <div>
            <div>
              <Link
                href={`/${pagePart}/${animation.url}`}
                className="animation-list__item-link font-bold"
                onClick={clickHandler}
              >
                {selected && (
                  <i className="fas fa-check-circle animation-list__item-selected-icon text-blue-500 mr-1" />
                )}
                {animation.title}{" "}
                {dailyAnimationCount > 1 ? (
                  <span className="text-sm shadow-md inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline rounded-full bg-gray-400 text-white">
                    x{`${dailyAnimationCount}`}
                  </span>
                ) : null}
              </Link>
            </div>
            <div className="text-xs mt-0.5 mb-0.5">
              <Reactions
                reactions={animation.reactions}
                comments={animation.commentCount}
              />
            </div>
            {showPublic && animation.public ? (
              <span className="text-xs shadow-md inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline rounded-full bg-orange-400 text-black mr-1">
                <i className="fas fa-globe-europe" /> In Gallery
              </span>
            ) : null}
            {animation.pixelMode === PixelMode.Pixelated ? (
              <span className="text-xs shadow-md inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline rounded-full bg-blue-200 text-black mr-1">
                <i className="fas fa-th-large"></i> Pixel Art
              </span>
            ) : null}
            {animation.type === AnimationType.Comic ? (
              <span className="text-xs shadow-md inline-block py-0.5 px-2 leading-none text-center whitespace-nowrap align-baseline rounded-full bg-green-200 text-black mr-1">
                <i className="fas fa-book-open"></i> Comic
              </span>
            ) : null}
          </div>
        </div>
        <div className="text-sm absolute bottom-0 left-0 m-1">
          {userData && <UserPill user={userData} />}
        </div>
      </div>
    </li>
  );
};
