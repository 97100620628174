"use client";
import React, { FC } from "react";
import { SHA1 } from "crypto-js";
import Link from "next/link";

import { User } from "@/gql/generated/graphql";
import { textColorForBackground } from "@/lib/text-color-for-background";

import Avatar from "../avatar/avatar";

export type UserPillUser = Pick<User, "name" | "theme" | "avatarUrl">;

type UserPillProps = {
  user: UserPillUser;
  prefix?: string;
  suffix?: string;
  onClick?: () => void;
  small?: boolean;
  nonInteractive?: boolean;
  stillImage?: boolean;
};

export const UserPill: FC<UserPillProps> = ({
  user,
  prefix = "",
  suffix = "",
  onClick,
  small = false,
  nonInteractive = false,
  stillImage = false,
}) => {
  if (!user) return null;
  let themeColour = user.theme?.username;
  const classes =
    "font-bold shadow-md inline-block pr-1 text-center whitespace-nowrap relative rounded-md overflow-hidden inline-flex items-center align-text-bottom mr-1";

  if (!themeColour) {
    const hash = SHA1(user.name).toString();
    themeColour = `#${hash.slice(0, 2)}${hash.slice(2, 4)}${hash.slice(4, 6)}`;
  }

  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={classes}
        style={{
          background: themeColour,
          color: textColorForBackground(themeColour),
          ...(small ? { maxWidth: "10rem" } : {}),
        }}
      >
        {prefix}
        {user.name}
        {suffix}
      </button>
    );
  }

  if (nonInteractive) {
    return (
      <span
        className={classes}
        style={{
          background: themeColour,
          color: textColorForBackground(themeColour),
          height: "1.25em",
          ...(small ? { maxWidth: "10rem" } : {}),
        }}
      >
        <span className="inline-block relative aspect-square h-full mr-1">
          <Avatar
            user={user}
            className="rounded-l-md"
            stillImage={stillImage}
          />
        </span>
        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {prefix}
          {user.name}
          {suffix}
        </span>
      </span>
    );
  }

  return (
    <Link
      href={`/studio/${user.name}`}
      className={classes}
      style={{
        background: themeColour,
        color: textColorForBackground(themeColour),
        height: "1.25em",
        ...(small ? { maxWidth: "10rem" } : {}),
      }}
    >
      <span
        className="relative h-full pr-1 rounded-l-md"
        style={{ aspectRatio: "auto 1" }}
      >
        <Avatar
          user={user}
          className="pr-1 rounded-l-md"
          stillImage={stillImage}
        />
      </span>
      <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {prefix}
        {user.name}
        {suffix}
      </span>
    </Link>
  );
};
