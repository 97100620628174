"use client";
import React from "react";
import Image from "next/image";

import { User } from "@/gql/generated/graphql";
import { useModal } from "@/hooks/use-modal";
import config from "@/lib/config";

import ChooseAvatarModal from "../modal/components/choose-avatar";

const Avatar = ({
  user,
  className = "",
  editable = false,
  stillImage = false,
}: {
  user: Pick<User, "avatarUrl">;
  className?: string;
  editable?: boolean;
  stillImage?: boolean;
}) => {
  const { openModal } = useModal();
  const clickHandler = editable
    ? () => openModal(() => <ChooseAvatarModal />)
    : undefined;

  if (user.avatarUrl) {
    return (
      <Image
        className={`${className} ${editable ? "cursor-pointer" : ""}`}
        src={`${config.s3Path}/${user.avatarUrl}/${
          stillImage ? "thumb.png" : "animation.gif"
        }`}
        onClick={clickHandler}
        alt=""
        fill
      />
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className={`h-full inline-block p-1 fill-current align-top ${
        editable ? "cursor-pointer" : ""
      }`}
      onClick={clickHandler}
    >
      <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
    </svg>
  );
};

export default Avatar;
