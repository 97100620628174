import React, { FC, Fragment } from "react";
import Image from "next/image";

import {
  Animation,
  CoreAnimationFieldsFragment,
  PixelMode,
} from "@/gql/generated/graphql";
import config from "@/lib/config";
type AnimationContentProps = {
  animation: CoreAnimationFieldsFragment;
};

const AnimationContent: FC<AnimationContentProps> = ({ animation }) => {
  const thumbFilename = animation.schemaVersion > 1 ? "poster" : "0";
  const staticVersion = animation.encoded ? animation.version : "default";

  return (
    <Fragment>
      {animation.pixelMode === PixelMode.Pixelated ? (
        <Image
          style={{ imageRendering: "pixelated" }}
          src={`${config.s3Path}/${animation.url}/animation.gif?v=${
            staticVersion ?? 0
          }`}
          className="thread-animation"
          alt=""
          width={animation.width}
          height={animation.height}
        ></Image>
      ) : (
        <video
          loop
          controls={false}
          playsInline
          muted
          autoPlay
          poster={`${config.s3Path}/${animation.url}/${thumbFilename}.png?v=${
            staticVersion ?? 0
          }`}
          className="thread-animation"
        >
          <source
            src={`${config.s3Path}/${animation.url}/animation.mp4?v=${
              staticVersion ?? 0
            }`}
            type="video/mp4"
          />
        </video>
      )}
    </Fragment>
  );
};

export default AnimationContent;
