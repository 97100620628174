import React, { ComponentProps, StyleHTMLAttributes } from "react";

type TextInputProps = ComponentProps<"input"> & {
  className?: string;
  inline?: boolean;
  type?: HTMLInputElement["type"];
  style?: StyleHTMLAttributes<HTMLDivElement>;
  label?: string;
  id?: string;
  defaultValue?: string;
  maxLength?: number;
  autoCapitalize?: string;
  // onChange?: (event: unknown) => void;
};

type TextInputRef = HTMLInputElement;

const TextInput = React.forwardRef<TextInputRef, TextInputProps>(
  (
    { className = "", inline, type = "text", style = {}, label, ...props },
    ref,
  ) => {
    return (
      <div
        style={{ display: inline ? "inline-block" : "block", ...style }}
        className="my-2"
      >
        {label && (
          <label
            className="block text-sm font-medium text-gray-700 my-2"
            htmlFor={props.id}
          >
            {label}
          </label>
        )}
        <input
          type={type}
          className={`rounded-md ${
            inline ? "" : "w-full"
          } shadow-inner p-1 border border-gray-300 ${className}`}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);

TextInput.displayName = "TextInput";

export default TextInput;
