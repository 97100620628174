import sha1 from "crypto-js/sha1";

import { User } from "@/gql/generated/graphql";

type UsernameThemeColourUser = Pick<User, "theme" | "name">;

const usernameThemeColour = (user: UsernameThemeColourUser): string => {
  let themeColour = user.theme?.username;

  if (!themeColour) {
    const hash = sha1(user.name).toString();
    themeColour = `#${hash.slice(0, 2)}${hash.slice(2, 4)}${hash.slice(4, 6)}`;
  }

  return themeColour;
};

export default usernameThemeColour;
