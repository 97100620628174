import React, { ComponentProps, FC, Fragment } from "react";
import Link from "next/link";

import { AnimationContent } from "@/components";
import Button from "@/components/button";
import IfUserIs from "@/components/if-user-is";
import { UserPill, UserPillUser } from "@/components/user-pill/user-pill";
import {
  Animation,
  CoreAnimationFieldsFragment,
} from "@/gql/generated/graphql";

type ThreadAnimationEntityProps = {
  animation: CoreAnimationFieldsFragment;
  voteButton?: boolean;
  onVotePressed?: (animationId: number) => void;
  voted?: boolean;
  votes?: number;
};

const ThreadAnimationEntity: FC<ThreadAnimationEntityProps> = ({
  animation,
  voteButton,
  onVotePressed,
  voted,
  votes,
}) => {
  const _onVotePressed = () => {
    onVotePressed && onVotePressed(animation.id);
  };

  return (
    <span className="thread-animation-container max-w-sm flex-auto shadow-lg">
      <AnimationContent animation={animation} />
      <span className="thread-animation-info block">
        <Link href={`/animation/${animation.url}`}>{animation.title}</Link> by{" "}
        <UserPill user={animation.user} />
      </span>
      {(voteButton || votes !== undefined) && (
        <span className="block p-2">
          <span className="p-2">
            {votes} {votes === 1 ? "vote" : "votes"}
          </span>
          {!voted && voteButton && (
            <Fragment>
              <IfUserIs signedIn>
                <Button onClick={_onVotePressed}>Vote for this entry</Button>
              </IfUserIs>
              <IfUserIs not signedIn>
                <Button href="#" disabled>
                  Sign-in to vote!
                </Button>
              </IfUserIs>
            </Fragment>
          )}
          {voted && voteButton && (
            <Fragment>
              <IfUserIs signedIn>
                <Button
                  onClick={_onVotePressed}
                  type="positive"
                  icon="fas fa-check"
                >
                  Voted!
                </Button>
              </IfUserIs>
              <IfUserIs not signedIn>
                <Button href="#" disabled>
                  Sign-in to vote!
                </Button>
              </IfUserIs>
            </Fragment>
          )}
        </span>
      )}
    </span>
  );
};

export default ThreadAnimationEntity;
