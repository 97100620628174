import React, { FC } from "react";

const PageHeadingPlaceholder: FC = () => {
  return (
    <h2 className="mb-0 px-2 sm:px-4 py-2 sm:rounded-t-md shadow-md bg-gradient-to-r from-gray-200 to-gray-500 animate-pulse">
      &nbsp;
    </h2>
  );
};

export default PageHeadingPlaceholder;
