import React from "react";

import Button from "@/components/button";
import { ButtonBar, Module } from "@/components/layouts";
import { ButtonGroup } from "@/components/layouts/button-group";
import { useModal } from "@/hooks/use-modal";

import ModalTitle from "./modal-title";

export default function ReportPostFailedModal() {
  const { closeModal } = useModal();

  return (
    <div className="text-center">
      <ModalTitle>Report failed</ModalTitle>
      <Module>
        <p>
          Sorry, something went wrong and your report was not sent. Please try
          again later.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-check-circle"
            onClick={closeModal}
            type="positive"
            rightAlign
          >
            Ok
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
