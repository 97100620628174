import React, { ReactNode } from "react";

const ModalTitle = ({ children }: { children: ReactNode }) => {
  return (
    <h3 className="mb-0 px-2 sm:px-4 py-2 sm:rounded-t-md bg-yellow-200 shadow-md">
      {children}
    </h3>
  );
};

export default ModalTitle;
