import { graphql } from "../generated";

export const MY_STUDIO = graphql(`
  query GetMyStudio(
    $offset: Int
    $limit: Int
    $folderName: String
    $folderId: String
    $types: [AnimationType!]!
  ) {
    privateAnimations(
      offset: $offset
      limit: $limit
      folderName: $folderName
      folderId: $folderId
      types: $types
    ) {
      ...AnimationListItemFields
      altThumbnail
      reactions {
        laugh
        dizzy
        stars
        love
      }
      commentCount
    }
    privateAnimationsCount(folderName: $folderName, folderId: $folderId)
    animationFolders {
      id
      name
    }
  }
`);
