import React from "react";

import Button from "@/components/button";
import { ButtonBar, Module } from "@/components/layouts";
import { ButtonGroup } from "@/components/layouts/button-group";
import { useModal } from "@/hooks/use-modal";

import ModalTitle from "./modal-title";

export default function ReportPostSubmittedModal() {
  const { closeModal } = useModal();

  return (
    <div className="text-center">
      <ModalTitle>Report Submitted</ModalTitle>
      <Module>
        <p>Thank you for your report.</p>
        <p>
          If the comment is found to be inappropriate, it will be removed from
          the site as soon as possible.
        </p>
      </Module>
      <ButtonBar>
        <ButtonGroup align="right">
          <Button
            icon="fas fa-check-circle"
            onClick={closeModal}
            type="positive"
            rightAlign
          >
            Ok
          </Button>
        </ButtonGroup>
      </ButtonBar>
    </div>
  );
}
