import { useDispatch } from "react-redux";
import {
  combineReducers,
  configureStore,
  ThunkAction,
  UnknownAction,
} from "@reduxjs/toolkit";

import animationReducer from "./reducers/animation-reducer";
import editorReducer from "./reducers/editor-reducer";

const rootReducer = combineReducers({
  editor: editorReducer,
  animation: animationReducer,
});

export const createStore = (preloadedState = {}) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

// let preloadedState = {};

// if (typeof window !== "undefined") {
//   preloadedState = window?.initialStore ?? {};
// }

// export const store = createStore(preloadedState);
export const store = createStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<RetType> = ThunkAction<
  RetType,
  RootState,
  unknown,
  UnknownAction
>;

export const useAppDispatch: () => AppDispatch = useDispatch;
