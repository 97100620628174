"use client";
import React, { FC, useState } from "react";

import Button from "@/components/button";
import IfUserIs from "@/components/if-user-is";
import { Module } from "@/components/layouts";
import PostEditor from "@/components/post-editor";
import {
  CoreAnimationFieldsFragment,
  CoreThreadFieldsFragment,
  ThreadType,
} from "@/gql/generated/graphql";

import ThreadCompetitionEntries from "./thread-competition-entries";
import { ThreadPosts } from "./thread-posts";

import "./_thread.scss";

const MAX_POSTS_VISIBLE = 20;

type ThreadProps = {
  thread?: CoreThreadFieldsFragment | null;
  animation?: Pick<CoreAnimationFieldsFragment, "__typename" | "id">;
};

const Thread: FC<ThreadProps> = ({ thread, animation }) => {
  const [revealedPosts, setRevealedPosts] = useState(MAX_POSTS_VISIBLE);

  if (!thread && animation)
    return <PostEditor animation={animation} type={ThreadType.Animation} />;

  if (!thread) return null;

  const onShowMoreClicked = () => {
    setRevealedPosts(revealedPosts + MAX_POSTS_VISIBLE);
  };

  const onShowAllClicked = () => {
    setRevealedPosts(thread.posts.length);
  };

  const posts = [...thread.posts] || [];
  const firstPost = posts.shift();
  const visiblePosts = posts.slice(revealedPosts * -1);
  const hasUnrevealedPosts = visiblePosts.length < posts.length;
  const unrevealedPostsCount = posts.length - visiblePosts.length;

  return (
    <div>
      <Module>
        {thread.title && (
          <h3 className="thread-title shadow-md">
            {thread.title}
            {thread.locked ? <i className="fas fa-lock lock-icon" /> : ""}
            {thread.pinned ? (
              <i className="fas fa-thumbtack pinned-icon" />
            ) : null}
          </h3>
        )}
      </Module>
      <Module>
        {firstPost && (
          <ThreadPosts
            posts={[firstPost]}
            threadUserId={thread.userId}
            threadType={thread.type}
          />
        )}
        {thread.type === "COMPETITION" && (
          <ThreadCompetitionEntries thread={thread} />
        )}
        {hasUnrevealedPosts ? (
          <p className="my-4">
            {unrevealedPostsCount} older comment
            {unrevealedPostsCount > 1 ? "s" : ""}{" "}
            <Button icon="fas fa-angle-up" onClick={onShowMoreClicked}>
              show more
            </Button>
            &nbsp;
            <Button icon="fas fa-angle-double-up" onClick={onShowAllClicked}>
              show all
            </Button>
          </p>
        ) : null}
        <ThreadPosts
          posts={visiblePosts}
          threadUserId={thread.userId}
          threadType={thread.type}
        />
        <IfUserIs userId={thread.userId}>
          <p>
            This thread belongs to you. You can hide any posts you don&apos;t
            like. If you hide a post, it will be hidden from everyone.
          </p>
        </IfUserIs>
      </Module>
      {thread.locked ? (
        <Module>This thread has been locked. No replies can be made.</Module>
      ) : (
        <PostEditor
          threadId={thread.id}
          animation={animation}
          type={thread.type}
        />
      )}
    </div>
  );
};

export default Thread;
