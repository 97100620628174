"use client";
import React, { FC, MouseEvent } from "react";
import { useMutation } from "@apollo/client";

import {
  Animation,
  Reaction,
  ReactionAction,
  ReactionType,
  User,
} from "@/gql/generated/graphql";
import { MUTATE_REACTION } from "@/gql/queries/mutate-reaction";

import { useCurrentUser } from "../user-context-provider/user-context-provider";

import "./_reaction-selector.scss";

type ReactionSelectorProps = {
  animationId: Animation["id"];
  reactionsBreakdown: {
    user: Pick<User, "name" | "id">;
    type: Reaction["type"];
  }[];
};

export type UserReactions = {
  [ReactionType.Dizzy]?: boolean;
  [ReactionType.Laugh]?: boolean;
  [ReactionType.Love]?: boolean;
  [ReactionType.Stars]?: boolean;
};

const ReactionSelector: FC<ReactionSelectorProps> = ({
  animationId,
  reactionsBreakdown,
}) => {
  const currentUser = useCurrentUser();
  const [mutateReaction, { loading }] = useMutation(MUTATE_REACTION);

  const userReactions = reactionsBreakdown
    .filter((reaction) => reaction.user.id === currentUser?.id)
    .reduce<UserReactions>((reactions, reaction) => {
      reactions[reaction.type] = true;
      return reactions;
    }, {});

  const onReactionSelected = async (e: MouseEvent<HTMLButtonElement>) => {
    const { type } = e.currentTarget.dataset;

    if (
      type === ReactionType.Dizzy ||
      type === ReactionType.Laugh ||
      type === ReactionType.Love ||
      type === ReactionType.Stars
    ) {
      mutateReaction({
        variables: {
          animationId,
          type,
          action: userReactions[type]
            ? ReactionAction.Remove
            : ReactionAction.Add,
        },
      });
    }
  };

  return (
    <div className="reaction-selector">
      <ul className="reaction-selector__list">
        <li>
          <button
            className={`reaction-selector__button ${
              userReactions.love ? "reaction-selector__button--active" : ""
            }`}
            data-type="love"
            onClick={onReactionSelected}
            disabled={loading}
          >
            <i className="fas fa-heart" />
          </button>
        </li>
        <li>
          <button
            className={`reaction-selector__button ${
              userReactions.laugh ? "reaction-selector__button--active" : ""
            }`}
            data-type="laugh"
            onClick={onReactionSelected}
            disabled={loading}
          >
            <i className="fas fa-grin-squint-tears" />
          </button>
        </li>
        <li>
          <button
            className={`reaction-selector__button ${
              userReactions.stars ? "reaction-selector__button--active" : ""
            }`}
            data-type="stars"
            onClick={onReactionSelected}
            disabled={loading}
          >
            <i className="fas fa-grin-stars" />
          </button>
        </li>
        <li>
          <button
            className={`reaction-selector__button ${
              userReactions.dizzy ? "reaction-selector__button--active" : ""
            }`}
            data-type="dizzy"
            onClick={onReactionSelected}
            disabled={loading}
          >
            <i className="fas fa-dizzy" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ReactionSelector;
