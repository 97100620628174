import React, { FC } from "react";

import { Module } from "@/components/layouts";

import "./_user-error.scss";

export type UserErrors = string[];

type UserErrorProps = {
  errors: UserErrors;
};

const UserError: FC<UserErrorProps> = ({ errors }) => {
  if (errors.length === 0) return null;
  return (
    <Module>
      <div className="error">
        {errors.map((error, errorKey) => (
          <p key={errorKey} className="error-item">
            <i className="fas fa-exclamation-triangle" /> {error}
          </p>
        ))}
      </div>
    </Module>
  );
};

export default UserError;
