import { ApolloClient } from "@apollo/client";
import { AnyAction, ThunkAction } from "@reduxjs/toolkit";

import { AnimationType, PixelMode } from "@/gql/generated/graphql";
import { ANIMATION } from "@/gql/queries/animation";
import { MUTATE_ANIMATION } from "@/gql/queries/mutate-animation";
import { rgbToHex } from "@/lib/canvas/utils";
import { serialiseSequence } from "@/lib/editor-helpers";
import { cleanupService, save as saveService } from "@/lib/services/animation";
import * as animationActions from "@/lib/state/actions/animation-actions";
import { AppDispatch, AppThunk, RootState } from "@/lib/state/store";

import {
  EDITOR_DB_SAVE_STATE,
  EDITOR_DB_STATE,
  EditorFramePickerMode,
  EditorTool,
} from "../reducers/editor-reducer";

const setLayerVisibility = function ({
  layerIndex,
  visible,
}: {
  layerIndex: number;
  visible: boolean;
}) {
  return {
    type: "EDITOR/SET_LAYER_VISIBILITY",
    payload: {
      layerIndex,
      visible,
    },
  };
};

const setFramePickerLayerVisibility = function ({
  layerIndex,
  visible,
}: {
  layerIndex: number;
  visible: boolean;
}) {
  return {
    type: "EDITOR/SET_FRAME_PICKER_LAYER_VISIBILITY",
    payload: {
      layerIndex,
      visible,
    },
  };
};

const onToolChanged = function (type: EditorTool) {
  return {
    type: "EDITOR/TOOL_CHANGED",
    payload: {
      type,
    },
  };
};

const goToFrame = function (index: number) {
  return {
    type: "EDITOR/GO_TO_FRAME",
    payload: { index },
  };
};

const goToFrameId = function (id: string): AppThunk<void> {
  return (dispatch, getState) => {
    const index = getState().animation.sequence.findIndex(
      (sequenceItem) => sequenceItem.id === id,
    );
    return dispatch({
      type: "EDITOR/GO_TO_FRAME",
      payload: { index },
    });
  };
};

const setColourFromEyeDropper = (
  pixelData: Uint8ClampedArray,
): AppThunk<void> => {
  return (dispatch, getState) => {
    const hex = rgbToHex(pixelData[0], pixelData[1], pixelData[2]);
    const opacity = pixelData[3] / 255;
    const { currentColourIndex } = getState().editor;
    dispatch(animationActions.setColourAction(currentColourIndex, hex));
    dispatch(onOpacityChanged(opacity));
  };
};

const nextFrame = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const state = getState();
    const { currentFrameIndex } = state.editor;
    const { sequence } = state.animation;
    const newIndex =
      currentFrameIndex < sequence.length - 1 ? currentFrameIndex + 1 : 0;
    dispatch(goToFrame(newIndex));
  };
};

const previousFrame = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const state = getState();
    const { currentFrameIndex } = state.editor;
    const { sequence } = state.animation;
    const newIndex =
      currentFrameIndex > 0 ? currentFrameIndex - 1 : sequence.length - 1;
    dispatch(goToFrame(newIndex));
  };
};

const toggleFramePicker = function () {
  return {
    type: "EDITOR/TOGGLE_FRAME_PICKER",
    payload: {},
  };
};

const onBrushSizeChanged = function (size: number) {
  return {
    type: "EDITOR/BRUSH_SIZE_CHANGED",
    payload: {
      size,
    },
  };
};

const onOpacityChanged = function (opacity: number) {
  return {
    type: "EDITOR/OPACITY_CHANGED",
    payload: {
      opacity,
    },
  };
};

const onColourChanged = function (index: number) {
  return {
    type: "EDITOR/COLOUR_CHANGED",
    payload: {
      index,
    },
  };
};

const resetEditor = function () {
  return {
    type: "EDITOR/RESET",
  };
};

const startPlayback = function () {
  return {
    type: "EDITOR/PLAYBACK_START",
    payload: {},
  };
};

const stopPlayback = function () {
  return {
    type: "EDITOR/PLAYBACK_STOP",
    payload: {},
  };
};

const undoStackPush = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const { editor, animation } = getState();

    dispatch({
      type: "EDITOR/REDO_STACK_RESET",
    });

    dispatch({
      type: "EDITOR/UNDO_STACK_PUSH",
      payload: {
        frameIndex: editor.currentFrameIndex,
        layerIndex: editor.currentLayerIndex,
        sequence: animation.sequence,
        layers: animation.layers,
      },
    });
  };
};

const createNew = ({
  width,
  height,
  pixelMode,
  type,
}: {
  width: number;
  height: number;
  pixelMode: PixelMode;
  type: AnimationType;
}) => {
  return (dispatch: AppDispatch) => {
    dispatch(resetEditor());

    return dispatch<Promise<void>>(
      animationActions.createNew({
        width,
        height,
        pixelMode,
        type,
      }),
    ).then(() => {
      dispatch(resetEditorLayers());
      dispatch(goToFrame(0));
    });
  };
};

const undo = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const { editor, animation } = getState();

    // Current state -> redo stack before undo
    dispatch({
      type: "EDITOR/REDO_STACK_PUSH",
      payload: {
        frameIndex: editor.currentFrameIndex,
        layerIndex: editor.currentLayerIndex,
        sequence: [...animation.sequence],
        layers: [...animation.layers],
      },
    });

    const undoItem = editor.undoStack[editor.undoStack.length - 1];
    dispatch({
      type: "EDITOR/GO_TO_LAYER",
      payload: undoItem.layerIndex,
    });
    dispatch(goToFrame(undoItem.frameIndex));
    dispatch({
      type: "ANIMATION/SET_FROM_UNDO",
      payload: {
        layers: undoItem.layers,
        sequence: undoItem.sequence,
      },
    });
    dispatch(undoStackPop());
  };
};

const redo = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const { editor, animation } = getState();

    // Current state -> undo stack before redo
    dispatch({
      type: "EDITOR/UNDO_STACK_PUSH",
      payload: {
        frameIndex: editor.currentFrameIndex,
        layerIndex: editor.currentLayerIndex,
        sequence: [...animation.sequence],
        layers: [...animation.layers],
      },
    });

    const redoItem = editor.redoStack[editor.redoStack.length - 1];
    dispatch({
      type: "EDITOR/GO_TO_LAYER",
      payload: redoItem.layerIndex,
    });
    dispatch(goToFrame(redoItem.frameIndex));
    dispatch({
      type: "ANIMATION/SET_FROM_UNDO",
      payload: {
        layers: redoItem.layers,
        sequence: redoItem.sequence,
      },
    });
    dispatch(redoStackPop());
  };
};

const undoStackPop = function () {
  return {
    type: "EDITOR/UNDO_STACK_POP",
    payload: {},
  };
};

const redoStackPop = function () {
  return {
    type: "EDITOR/REDO_STACK_POP",
    payload: {},
  };
};

const nextLayer = () => {
  return {
    type: "EDITOR/NEXT_LAYER",
  };
};

const previousLayer = () => {
  return {
    type: "EDITOR/PREVIOUS_LAYER",
  };
};

const setFramePickerMode = function (mode: EditorFramePickerMode) {
  return {
    type: "EDITOR/SET_FRAME_PICKER_MODE",
    payload: mode,
  };
};

const resetEditorLayers = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const { animation } = getState();
    dispatch({
      type: "EDITOR/GO_TO_LAYER",
      payload: animation.layers.length - 1,
    });
    dispatch({
      type: "EDITOR/SET_LAYERS",
      payload: animation.layers.map(() => true),
    });
    dispatch({
      type: "EDITOR/SET_FRAME_PICKER_LAYERS",
      payload: animation.layers.map(() => true),
    });
  };
};

const setDbState = (isDbSuported: EDITOR_DB_STATE) => {
  return {
    type: "EDITOR/SET_DB_STATE",
    payload: isDbSuported,
  };
};

const setDbSaveState = (saveState: EDITOR_DB_SAVE_STATE) => {
  return {
    type: "EDITOR/SET_DB_SAVE_STATE",
    payload: saveState,
  };
};

const toggleOnionSkins = () => {
  return {
    type: "EDITOR/TOGGLE_ONION_SKINS",
  };
};

const toggleGrid = () => {
  return {
    type: "EDITOR/TOGGLE_GRID",
  };
};

export {
  createNew,
  goToFrame,
  goToFrameId,
  nextFrame,
  nextLayer,
  onBrushSizeChanged,
  onColourChanged,
  onOpacityChanged,
  onToolChanged,
  previousFrame,
  previousLayer,
  redo,
  redoStackPop,
  resetEditor,
  resetEditorLayers,
  setColourFromEyeDropper,
  setDbSaveState,
  setDbState,
  setFramePickerLayerVisibility,
  setFramePickerMode,
  setLayerVisibility,
  startPlayback,
  stopPlayback,
  toggleFramePicker,
  toggleGrid,
  toggleOnionSkins,
  undo,
  undoStackPop,
  undoStackPush,
};
