"use client";
import React, { FC } from "react";
import { useQuery } from "@apollo/client";

import DropdownMenu from "@/components/dropdown-menu/dropdown-menu";
import { GET_FOLDER_QUERY } from "@/gql/queries/animation-folder";
import { useModal } from "@/hooks/use-modal";

import ManageFoldersModal from "../manage-folders";

type FolderSelectorProps = {
  currentFolderId: string | null;
  onFolderSelected: (id: string | null) => void;
};

const FolderSelector: FC<FolderSelectorProps> = ({
  currentFolderId,
  onFolderSelected,
}) => {
  const { data, loading } = useQuery(GET_FOLDER_QUERY);
  const { openModal } = useModal();

  if (loading || !data) return null;

  const selectedFolder = loading
    ? null
    : data.animationFolders.find((folder) => folder.id === currentFolderId);

  const folderMenuOptions = loading
    ? []
    : [
        {
          icon: "fas fa-cog",
          onClick: () => {
            openModal(() => <ManageFoldersModal />);
          },
          text: "Manage folders",
        },
        {
          icon: "fas fa-folder",
          text: "(no folder)",
          onClick: () => onFolderSelected(null),
        },
        ...data.animationFolders.map((folder) => ({
          icon: "fas fa-folder",
          text: folder.name,
          onClick: () => onFolderSelected(folder.id),
        })),
      ];

  const menuButton = loading
    ? {
        text: "Loading...",
      }
    : { text: selectedFolder?.name ?? "(no folder)" };

  return (
    <DropdownMenu items={folderMenuOptions} align="left" button={menuButton} />
  );
};

export default FolderSelector;
