import React from "react";

import { Post, PostsFieldsFragment, Thread } from "@/gql/generated/graphql";

import { BlockContent } from "./post-block";

type PostBlocksProps = {
  post: PostsFieldsFragment;
  threadType: Thread["type"];
};

export const PostBlocks = ({ post, threadType }: PostBlocksProps) => {
  return post.blocks.map((block, index) => {
    if (block.type === "paragraph") {
      return (
        <p className="my-2" key={index}>
          <BlockContent
            key={index}
            entities={block.entities}
            threadType={threadType}
            parentPost={post}
          />
        </p>
      );
    } else {
      return (
        <blockquote
          key={index}
          className="px-2 py-1 text-xs border-l-2 border-gray-500 text-gray-600 bg-white bg-opacity-50"
        >
          <BlockContent
            key={index}
            entities={block.entities}
            threadType={threadType}
            parentPost={post}
          />
        </blockquote>
      );
    }
  });
};
