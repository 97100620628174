import * as Sentry from "@sentry/react";

import config from "./config";

// export const BREADCRUMB_LEVEL = {
//   FATAL: "fatal",
//   ERROR: "error",
//   WARNING: "warning",
//   INFO: "info",
//   DEBUG: "debug",
// };

export function captureError(e: unknown) {
  if (!config.features.sentry) {
    console.error("Logged error", e);
    return;
  }
  Sentry.captureException(e);
}

export function addBreadcrumb({
  category,
  message,
  level = "info",
}: {
  category: string;
  message: string;
  level?: Sentry.SeverityLevel;
}) {
  if (!config.features.sentry) {
    console.info("Logged breadcrumb", category, message, `(${level})`);
    return;
  }
  Sentry.addBreadcrumb({ category, message, level });
}
