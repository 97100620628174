"use client";
import React, { FC, Fragment, MouseEvent, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";

import Button, { ButtonProps, getButtonStyles } from "@/components/button";

export type DropdownMenuEntry = {
  onClick?: (event: MouseEvent) => void;
  to?: string;
  icon?: string;
  text: ReactNode;
  buttonProps?: Record<string, unknown>;
};

type DropDownMenuProps = {
  button: Omit<ButtonProps, "children"> & { text: string | ReactNode };
  items: DropdownMenuEntry[];
  align?: "left" | "right";
};

const DropdownMenu: FC<DropDownMenuProps> = ({
  button,
  items,
  align = "right",
  ...buttonProps
}) => {
  const renderEntry = (entry: DropdownMenuEntry, index: number) => {
    let component = null;

    if (entry.onClick) {
      component = (
        <Button
          type="menu"
          icon={entry.icon}
          onClick={entry.onClick}
          {...entry.buttonProps}
        >
          {entry.text}
        </Button>
      );
    } else if (entry.to) {
      component = (
        <Button
          type="menu"
          icon={entry.icon}
          href={entry.to}
          {...entry.buttonProps}
        >
          {entry.text}
        </Button>
      );
    }

    return <Menu.Item key={index}>{component}</Menu.Item>;
  };

  const menuAlign = align === "right" ? "right-0" : "left-0";

  return (
    <div className="relative inline-block text-left">
      <Menu>
        {({ open }) => (
          <Fragment>
            <div>
              <Menu.Button className={getButtonStyles(button)} {...buttonProps}>
                {button.icon ? <i className={`${button.icon} pr-2`} /> : null}
                {button.text}
                <i className="fas fa-chevron-down ml-1 opacity-50 text-xs" />
              </Menu.Button>
            </div>

            {/* Use the Transition + open render prop argument to add transitions. */}
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              className={`p-1 z-10 origin-top-right absolute ${menuAlign} whitespace-nowrap rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
            >
              <Menu.Items static className="focus:outline-none">
                {items.map(renderEntry)}
              </Menu.Items>
            </Transition>
          </Fragment>
        )}
      </Menu>
    </div>
  );
};

export default React.memo(DropdownMenu);
