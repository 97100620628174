import React, { ComponentProps, ReactNode } from "react";

type PageHeadingProps = ComponentProps<"h2"> & {
  children: ReactNode;
};

const PageHeading = ({ children, ...props }: PageHeadingProps) => {
  return (
    <h2
      className="mb-0 px-2 sm:px-4 py-2 sm:rounded-t-md bg-green-50 shadow-md break-words"
      {...props}
    >
      {children}
    </h2>
  );
};

export default PageHeading;
